import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import PaymentModal from '@app/src/Components/Subscription/PaymentModal/PaymentModal';
import TaxFlowCard from '@app/src/Components/TaxFlow/Common/TaxFlowCard';
import TaxFlowInfoModalWindow from '@app/src/Components/TaxFlow/Common/TaxFlowInfoModalWindow';
import TaxFlowPremiumUpsellModal from '@app/src/Components/TaxFlow/Question/TaxFlowPremiumUpsellModal';
import { TaxFlowHome } from '@app/src/taxflow/main/components/TaxFlowHome';
import TaxFlowQuestionContent from '@app/src/taxflow/main/components/TaxFlowQuestionContent';
import { useUpdatePageOnUIStageChange } from '@app/src/taxflow/main/hooks/useUpdatePageOnUIStageChange';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';

const TaxFlowContainer = ({ isPaymentModalOpen }) => {
  const location = useLocation();
  useUpdatePageOnUIStageChange();
  return (
    <StripeProvider>
      <div className='taxflow-wrapper'>
        <div className='taxflow-content'>
          <TaxFlowNavigationPanel />
          <div className='taxflow-content-center'>
            <TaxFlowCard location={location}>
              <div className={'taxflow-box-wrap'}>
                <div className='taxflow-box-wrap-left-spacer' />
                <Switch>
                  <Route path='/tax-filing/home' component={TaxFlowHome} />
                  <Route component={TaxFlowQuestionContent} />
                </Switch>
                <div className='taxflow-box-wrap-right-spacer' />
              </div>
            </TaxFlowCard>
            <TaxFlowInfoModalWindow />
            <TaxFlowPremiumUpsellModal />
            {isPaymentModalOpen && <PaymentModal history={this.props.history} />}
          </div>
        </div>
      </div>
    </StripeProvider>
  );
};

export default TaxFlowContainer;
