import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import qs from 'qs';
import { connect } from 'react-redux';
import { usePrevious } from 'react-use';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import TaxFlowQuestion from '@app/src/Components/TaxFlow/Question/TaxFlowQuestion';
import { connectSocket, disconnectSocket } from '@app/src/actions/socketActions';
import { useGetCurrentQuestionnaireQuestionQuery } from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { isPaymentModalOpenSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { formErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { getErrors } from '@app/src/services/taxValidationService';
import { isValidInputSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  getQuestionAnswer,
  updateQuestionAnswer,
  advanceToNextUrl,
  trackQuestionAnswer,
  advanceToCurrentSectionPage
} from '@app/src/taxflow/main/services/mainService';
import { getQuestionsAnswered } from '@app/src/taxflow/main/services/taxFlowDataService';
import { getNextButtonLabel, isPrev, getSlugByLocation } from '@app/src/taxflow/main/utils/mainUtils';
import { setIsNextButtonLoading } from '@app/src/taxflow/shared/actions/sharedActions';
import { scrollToTop } from '@app/src/utils';

const TaxFlowQuestionContent = ({
  isAuthenticated,
  slug,
  collectionId,
  disconnectSocket,
  advanceToCurrentSectionPage,
  isPrev,
  nextButtonLabel,
  location,
  updateQuestionAnswer,
  advanceToNextUrl,
  getQuestionAnswer
}) => {
  const [setSocketConnected, socketConnected] = useState(false);
  const { data: currentQuestionnaireQuestion } = useGetCurrentQuestionnaireQuestionQuery();
  const stripe = useStripe();
  const elements = useElements();
  const previousSlug = usePrevious(slug);
  const previousQuestionnaireQuestion = usePrevious(currentQuestionnaireQuestion);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket]);

  const getQuestionConditional = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    if (!slug) {
      await advanceToCurrentSectionPage({ history });
      return;
    }

    if (!socketConnected) {
      connectSocket();
      setSocketConnected(true);
    }

    await getQuestionAnswer({
      slug,
      location,
      history
    });

    scrollToTop();
  }, [advanceToCurrentSectionPage, isAuthenticated, location, setSocketConnected, slug, getQuestionAnswer]);

  useEffect(() => {
    if (previousSlug === slug && _.isEqual(currentQuestionnaireQuestion, previousQuestionnaireQuestion)) {
      return;
    }

    getQuestionConditional();
  }, [
    currentQuestionnaireQuestion,
    previousQuestionnaireQuestion,
    collectionId,
    slug,
    previousSlug,
    getQuestionConditional
  ]);

  const onNext = async () => {
    const { hasNewErrors } = await updateQuestionAnswer({
      stripe,
      elements,
      handleErrors: true
    });

    if (hasNewErrors) {
      // Form invalid
      const errorElement = document.querySelector(
        '.form-group:has(.Mui-error), .form-group:has(.tax-validation-warning-blue)'
      );

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      await advanceToNextUrl({ history, location });
    }
  };

  if (!isAuthenticated) {
    return <LoginRedirect location={location} />;
  }

  if (!slug) {
    return null;
  }

  return <TaxFlowQuestion onNext={onNext} isPrev={isPrev} NextButtonLabel={nextButtonLabel} />;
};

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(state, ['taxFlow', 'currentQuestion']),
  isPrev: isPrev({
    slug: _.get(state, ['taxFlow', 'currentQuestion', 'slug'])
  }),
  nextButtonLabel: getNextButtonLabel({
    question: _.get(state, ['taxFlow', 'currentQuestion'])
  }),
  slug: getSlugByLocation(props.location),
  isAuthenticated: _.get(state, ['auth', 'isAuthenticated']),
  collectionId: _.get(qs.parse(props.location.search.slice(1), { ignoreQueryPrefix: true }), 'collectionId'),
  isValidInput: isValidInputSelector(state, props),
  formErrors: formErrorsSelector(state, props),
  isPaymentModalOpen: isPaymentModalOpenSelector(state)
});

const mapDispatchToProps = {
  getQuestionAnswer,
  updateQuestionAnswer,
  getQuestionsAnswered,
  advanceToNextUrl,
  getErrors,
  setIsNextButtonLoading,
  trackQuestionAnswer,
  connectSocket,
  disconnectSocket,
  advanceToCurrentSectionPage
};

const ConnectedTaxFlowQuestionContent = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionContent);

export default ConnectedTaxFlowQuestionContent;
