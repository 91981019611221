import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_FORM_MULTI,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD
} from '@app/src/constants/constants';
import { Url_SIGNUP_PHONE_ONLY_PHONE } from '@app/src/constants/onboardingConstants';
import {
  currentUploadsSelector,
  selectedBulkManualEntryItemSelector,
  isQuestionnaireFlowSelector
} from '@app/src/selectors/taxFlowSelectors';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import {
  bulkUploadItemsSelector,
  formUploadAttemptsSelector,
  isCurrentCollectionUploadedSelector,
  nonDeletedUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { isTaxfilingNonskippable } from '@app/src/taxflow/main/utils/mainUtils';
import { CAR_SLUGS } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
  SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { HOME_SLUGS } from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INDUSTRY,
  SLUG__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_INVEST_UNIFICATION,
  SLUG__INCOME_INVEST_INFO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON,
  SLUG__DEPENDENT_RELATIONSHIP_TYPE,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_STATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__CREDIT_ADD_MORE,
  SLUG__FILING_QUALIFIED,
  SLUG__INCOME_ADD_MORE,
  SLUG__SPECIAL_EARLY_EXIT
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  COLLECTION_TYPE__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__DC_RENT_CREDIT_OPTIONS,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_HAVE_PIN,
  SLUG__SUBMIT_SELF_PIN_NUMBER,
  SLUG__SUBMIT_SPOUSE_PIN_NUMBER,
  SLUG__SUBMIT_DEBIT_MANUAL,
  SLUG__SUBMIT_PAY_NOW,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_DEBIT_MANUAL_OPTION,
  SLUG__SUBMIT_PAY_TEST,
  SLUG__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_CONFIRM_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { isPaymentValidSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { SLUG__CONTACT_SUPPORT, SLUG__PAST_RETURNS } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentQuestionSelector,
  currentCollectionIdSelector,
  currentAnswerSelector,
  queryResultsSelector,
  statusSelector,
  isAlabamaOrMinnesotaSelector,
  questionsAnsweredSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { hasQuestionError, isQuestionPresent } from '@app/src/utils/taxValidationUtils';

const questionSelector = (state, props) => props.question;
const overrideCollectionIdSelector = (state, props) => props.overridecollectionid;
export const errorsSelector = (state) => _.get(state, ['taxValidation', 'errors']);

export const filteredErrorsSelector = createSelector(
  [
    errorsSelector,
    questionsAnsweredSelector,
    isCurrentCollectionUploadedSelector,
    bulkUploadItemsSelector,
    currentQuestionSelector
  ],
  (errors, questionsAnswered, isCurrentCollectionUploaded, bulkUploadItems, currentQuestion) => {
    return _.some(bulkUploadItems, { formInputQuestionSlug: currentQuestion.slug }) && isCurrentCollectionUploaded
      ? errors
      : errors.filter(({ slug, coll_id }) => questionsAnswered.has(JSON.stringify({ slug, coll_id })));
  }
);

export const formErrorsSelector = createSelector(
  [filteredErrorsSelector, currentQuestionSelector, currentCollectionIdSelector, currentAnswerSelector, statusSelector],
  (errors, question, collectionId, answer, status) => {
    const collectionIds =
      question.slug === SLUG__INCOME_INVEST_INFO ? [collectionId, `${Number(collectionId) + 1}`] : [collectionId];

    return _.chain(errors)
      .filter(({ slug, coll_id }) => {
        if (_.get(question, 'question_type') === CATEGORY_TYPE_TAXFLOW_FORM) {
          const questionEndpointAttrs = _.chain(question)
            .get('sub_question', [])
            .filter((subQuestion) =>
              isQuestionPresent({
                currentQuestion: question,
                question: subQuestion,
                answer,
                status
              })
            )
            .flatMap((item) => {
              if (item.question_type !== CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
                return item.endpoint_attr;
              }
              return _.map(item.sub_question, 'endpoint_attr');
            })
            .uniq()
            .value();
          if (question.sub_question.some((sq) => sq.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI)) {
            return questionEndpointAttrs.some((attr) => slug.includes(attr)) && collectionIds.includes(coll_id);
          }
          return questionEndpointAttrs.includes(slug) && collectionIds.includes(coll_id);
        }

        return slug === question.endpoint_attr && collectionIds.includes(coll_id);
      })
      .keyBy('slug')
      .value();
  }
);

const isPrefillSelector = createSelector(
  [formUploadAttemptsSelector, questionSelector, currentCollectionIdSelector],
  (formUploadAttempts, question, currentCollectionId) => {
    const collectionType = _.get(question, 'collectionType');
    const collectionId = currentCollectionId;
    if (_.isNil(collectionType) || _.isNil(collectionId)) {
      return false;
    }

    const collectionAttempt = _.get(formUploadAttempts, [collectionType, collectionId], {});
    return _.get(collectionAttempt, 'status') === 'prefilled';
  }
);

export const hasFieldErrorSelector = createSelector(
  [
    questionSelector,
    currentAnswerSelector,
    currentCollectionIdSelector,
    filteredErrorsSelector,
    queryResultsSelector,
    isPrefillSelector,
    overrideCollectionIdSelector
  ],
  (question, answer, collectionId, errors, queryResults, isPrefill, overrideCollectionId) => {
    if (
      question.slug === Url_SIGNUP_PHONE_ONLY_PHONE &&
      _.get(answer, ['value', Url_SIGNUP_PHONE_ONLY_PHONE, 'value'], '').startsWith('11')
    ) {
      return true;
    } else if (question.collectionType === COLLECTION_TYPE__STATE_RETURN) {
      const firstStateReturn = queryResults.find((result) => {
        return result.coll_type === COLLECTION_TYPE__STATE_RETURN && result.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN;
      });
      return firstStateReturn && _.get(answer, ['value']) === _.get(firstStateReturn, ['answer', 'value']);
    }

    let collectionErrors;
    if (question.collectionType === COLLECTION_TYPE__INCOME_INVEST) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === SLUG__INCOME_INVEST_UNIFICATION && result.coll_id === collectionId
      );
      const unifiedCollIds = _.get(unificationRecord, ['answer', 'value']) || [Number(collectionId)];
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          unifiedCollIds.includes(Number(error.coll_id)) &&
          error.slug === question.endpoint_attr &&
          (_.isNil(overrideCollectionId) || overrideCollectionId === Number(error.coll_id))
      );
    } else if (
      question.collectionType === COLLECTION_TYPE__STATE_INCOME ||
      question.collectionType === COLLECTION_TYPE__STATE_EXPENSES
    ) {
      if (_.isArray(question.slug)) {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && question.slug.includes(error.slug)
        );
      } else {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && error.slug === question.slug
        );
      }
    } else {
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          error.coll_id === collectionId &&
          error.slug === question.endpoint_attr
      );
    }

    const ocrErrors = collectionErrors.filter((error) => error.code === 'ocr_error');
    return (isPrefill && !!ocrErrors.length) || collectionErrors.length > ocrErrors.length;
  }
);

export const isTaxFilingNextEnabledSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    queryResultsSelector,
    statusSelector,
    isPaymentValidSelector,
    currentUploadsSelector,
    isAlabamaOrMinnesotaSelector,
    isQuestionnaireFlowSelector
  ],
  (question, answer, queryResults, status, isPaymentValid, currentUploads, isAlabamaOrMinnesota, isBulkUploadFlow) => {
    if (_.includes([SLUG__INCOME_ADD_MORE, SLUG__CREDIT_ADD_MORE], question.slug)) {
      return false;
    }

    if (
      question.slug === SLUG__SUBMIT_DEBIT_MANUAL &&
      _.get(answer, ['value', SLUG__SUBMIT_DEBIT_MANUAL_OPTION, 'value']) !== 'manual'
    ) {
      return false;
    }

    if (question.slug === SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD) {
      return false;
    }

    if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD) {
      const hasPendingUpload = currentUploads.some((upload) => upload.status === 'pending');

      // form upload is required for AL and MN residents
      return !hasPendingUpload && !isAlabamaOrMinnesota;
    }

    // required questions without default are non skippable
    if (
      [
        SLUG__SUBMIT_DEBIT,
        SLUG__SUBMIT_DEBIT_MANUAL,
        SLUG__SUBMIT_BANK_NUMBERS,
        SLUG__INCOME_FREELANCE_1099_TYPE,
        CAR_SLUGS.NAV_START,
        CAR_SLUGS.WORK_MILES_TRACKED,
        HOME_SLUGS.OPTIONS,
        HOME_SLUGS.TYPE,
        SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
        SLUG__DC_RENT_CREDIT_OPTIONS,
        SLUG__SUBMIT_SIGNATURE,
        SLUG__SUBMIT_EMAIL_INFO,
        SLUG__SUBMIT_CONFIRM_ID,
        SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
        SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
        SLUG__FILING_QUALIFIED
      ].includes(question.slug)
    ) {
      return !hasQuestionError({ question, answer, status, queryResults });
    }

    if (question.slug === SLUG__SUBMIT_PIN) {
      if (_.get(answer, ['value', SLUG__SUBMIT_HAVE_PIN, 'value']) !== '1') {
        return true;
      } else {
        const pinQuestion = question.sub_question.find((x) => x.slug === SLUG__SUBMIT_SELF_PIN_NUMBER);
        const pinMinLength = _.get(pinQuestion, ['question_meta', 'minLength'], 6);
        const selfPin = _.get(answer, ['value', SLUG__SUBMIT_SELF_PIN_NUMBER, 'value']);
        const spousePin = _.get(answer, ['value', SLUG__SUBMIT_SPOUSE_PIN_NUMBER, 'value']);
        const selfPinValid = !_.isNil(selfPin) ? _.isEmpty(selfPin) || selfPin.length >= pinMinLength : false;
        const isSpousePinPresent = isQuestionPresent({
          question: question.sub_question.find((x) => x.slug === SLUG__SUBMIT_SPOUSE_PIN_NUMBER),
          answer,
          status
        });
        const spousePinValid = !_.isNil(spousePin) ? _.isEmpty(spousePin) || spousePin.length >= pinMinLength : false;

        if (isSpousePinPresent) {
          return selfPinValid && spousePinValid && (!_.isEmpty(selfPin) || !_.isEmpty(spousePin));
        }

        return selfPinValid && !_.isEmpty(selfPin);
      }
    }

    if (question.slug === SLUG__SUBMIT_PAY_NOW) {
      return isPaymentValid;
    }

    if (question.slug === SLUG__SUBMIT_PAY_TEST) {
      return isPaymentValid || _.get(answer, ['value', 'paymentType']) === 'paymentRequest';
    }

    if (question.slug === SLUG__HOME_ADDRESS_DETAIL) {
      const addressSubquestions = question.sub_question || [];
      const multistateOnly = !addressSubquestions.some((q) => q.slug === SLUG__HOME_ADDRESS_STATE);

      if (isBulkUploadFlow && multistateOnly) {
        const multistateOption = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE, 'value']);
        if (_.isNil(multistateOption)) {
          return false;
        }

        const secondState = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE, 'value']);
        return multistateOption === '0' || !_.isEmpty(secondState);
      } else {
        const state = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE, 'value']);
        return !_.isNil(state) && !_.isEmpty(state);
      }
    }

    if (question.slug === SLUG__DEPENDENT_DETAIL) {
      const dependentFirstName = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME, 'value']);
      return !_.isNil(dependentFirstName) && !_.isEmpty(dependentFirstName);
    }

    if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
      const businessCode = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE, 'value']);
      const industry = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INDUSTRY, 'value']);
      return !_.isEmpty(businessCode) && !_.isEmpty(industry);
    }

    if (question.slug === SLUG__STATE_RETURN) {
      return isValidInput({ question, answer, status, queryResults });
    }

    if (
      question.slug === SLUG__SPECIAL_EARLY_EXIT ||
      question.slug === SLUG__CONTACT_SUPPORT ||
      question.slug === SLUG__PAST_RETURNS
    ) {
      return false;
    }

    if (
      question.slug === SLUG__DEPENDENT_RELATIONSHIP_DETAIL &&
      !_.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_TYPE, 'value'])
    ) {
      return false;
    }

    if (
      question.slug === SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL &&
      !_.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON, 'value'])
    ) {
      return false;
    }

    return true;
  }
);

export const isTaxFilingSkipEnabledSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    isTaxFilingNextEnabledSelector,
    isAlabamaOrMinnesotaSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    statusSelector,
    errorsSelector,
    formErrorsSelector,
    currentUploadsSelector,
    nonDeletedUploadAttemptsSelector,
    selectedBulkManualEntryItemSelector
  ],
  (
    currentQuestion,
    currentAnswer,
    isTaxFilingNextEnabled,
    isAlabamaOrMinnesota,
    queryResults,
    currentCollectionId,
    status,
    errors,
    formErrors,
    selectedBulkManualEntryItem
  ) => {
    const ocrErrors = (errors || []).filter(
      (error) =>
        error.coll_type === currentQuestion.collectionType &&
        error.coll_id === currentCollectionId &&
        error.code === 'ocr_error'
    );

    if (currentQuestion.slug === SLUG__BULK_UPLOAD_MANUAL_ENTRY) {
      return _.isNil(selectedBulkManualEntryItem);
    }

    return (
      !isOnboardingQuestion(currentQuestion) &&
      !isTaxfilingNonskippable(currentQuestion, isAlabamaOrMinnesota) &&
      isTaxFilingNextEnabled &&
      !_.isEmpty(formErrors) &&
      (!isValidInput({
        question: currentQuestion,
        answer: currentAnswer,
        status,
        queryResults,
        currentCollectionId,
        formErrors
      }) ||
        !_.isEmpty(ocrErrors))
    );
  }
);
