import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_API_OPTIONS } from '@app/src/api/constants';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { serverUrl } from '@app/src/global/Environment';

const baseUrl = serverUrl();

export const TAG__PAID = 'Paid';

const plaidApi = createApi({
  ...DEFAULT_API_OPTIONS,
  reducerPath: 'plaidApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}api/plaid/`,
    prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
  }),
  tagTypes: [TAG__PAID],
  endpoints: (builder) => ({
    getPaid: builder.query({
      query: () => ({
        url: 'get-tax-filing-purchase-status',
        method: 'GET'
      }),
      providesTags: [TAG__PAID],
      transformResponse: (response) => response.data.paid
    })
  })
});

export default plaidApi;

export const { useGetPaidQuery, useLazyGetPaidQuery } = plaidApi;

// Actions
export const getPaid = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: plaidApi.endpoints.getPaid.initiate, dispatch });
