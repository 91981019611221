import React, { useEffect } from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BankUnlinkedButton from '@app/src/Components/ExpenseReview/Common/BankUnlinkedButton/BankUnlinkedButton';
import ExpenseReviewExpensesEmpty from '@app/src/Components/ExpenseReview/ExpenseReviewExpensesEmpty';
import ExpenseReviewExpensesSavingsContainer from '@app/src/Components/ExpenseReview/ExpenseReviewExpensesSavingsContainer';
import ExpenseReviewMenuBar from '@app/src/Components/ExpenseReview/ExpenseReviewMenuBar';
import ExpenseReviewExpensesTable from '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTable';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import { setExpenseReviewModalType, setExpensesYear } from '@app/src/actions/expenseReviewActions';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import CloseIcon from '@app/src/assets/close.svg?react';
import { INACTIVE_USER_STATUSES } from '@app/src/constants/constants';
import history from '@app/src/keeperHistory';
import {
  expensesYearSelector,
  hasFiltersSelectedSelector,
  isInitialExpensesLoadSelector,
  loadingSelector,
  orderedExpensesSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getBankSummaryV2 } from '@app/src/services/bankService';
import { getExpenseWaiting, getInitialExpenses, getRules } from '@app/src/services/expenseReviewService';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import {
  accountDetailsSelector,
  hasActiveSubscriptionSelector,
  isPremiumSubscriberSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/ExpenseReview/ExpenseReview.scss';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpenses.scss';

const ExpenseReviewExpenses = ({
  isInitialExpensesLoad,
  hasActiveSubscription,
  className,
  getRules,
  expensesYear,
  getInitialExpenses,
  getExpenseWaiting,
  setExpenseReviewModalType,
  userStatus,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  isPremiumSubscriber,
  advanceToCurrentSectionPage,
  setExpensesYear,
  getBankSummaryV2
}) => {
  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    if (searchParams.has('year')) {
      const year = searchParams.get('year');

      if (year === `${moment().year()}` || year === `${moment().year() - 1}`) {
        setExpensesYear(year);
      }

      searchParams.delete('year');

      history.replace({
        search: searchParams.toString()
      });
    }
  }, [search, setExpensesYear]);

  useEffect(() => {
    if (INACTIVE_USER_STATUSES.includes(userStatus)) {
      setExpenseReviewModalType('paywall');
    }
  }, [userStatus, setExpenseReviewModalType]);

  useEffect(() => {
    const initExpenseReview = async () => {
      await getInitialExpenses();
      await getRules();
      await getBankSummaryV2();
    };

    initExpenseReview();
  }, [getInitialExpenses, expensesYear, getRules, getBankSummaryV2]);

  useEffect(() => {
    (async () => {
      await getExpenseWaiting();
    })();
  }, [getExpenseWaiting]);

  useGetCampaignQuery();

  if (!isInitialExpensesLoad && !hasActiveSubscription) {
    return (
      <div className='expense-review-expenses'>
        <ExpenseReviewExpensesSavingsContainer empty />
        <ExpenseReviewExpensesEmpty reason={'inactiveUser'} />
      </div>
    );
  }

  return (
    <div className={classNames('expense-review-expenses', className)}>
      <BankUnlinkedButton />
      <ExpenseReviewMenuBar className='expense-review-expenses-menu-bar' />
      <ExpenseReviewExpensesSavingsContainer className='expense-review-expenses-savings-container' />
      <ExpenseReviewExpensesTable className='expense-review-expenses-table' />
      {isPremiumSubscriber ? (
        <Dialog
          open={selfUnlockModalOpen}
          onClose={() => {
            setSelfUnlockModalOpen(false);
          }}
        >
          <DialogTitle sx={{ marginTop: 1.5, marginRight: 1.5, marginLeft: 1.5 }}>
            <div>{TAX_FILING_YEAR} deductions lock for editing</div>
            <IconButton
              aria-label='close'
              onClick={() => {
                setSelfUnlockModalOpen(false);
              }}
              sx={{
                color: 'primary.main',
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}>
            <DialogContentText>
              If you need to make changes, contact your assigned tax pro to unlock your return
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <TaxFlowConfirmModal
          open={selfUnlockModalOpen}
          setOpen={setSelfUnlockModalOpen}
          header='Edit your return and re-submit?'
          body="You'll need to re-submit for review after editing your return."
          // non breaking hyphen
          confirmText='Yes, edit and re&#8209;submit'
          onConfirm={async () => {
            trackActivity('self unlock: modal confirmed');

            await moveToNotStarted();
            await advanceToCurrentSectionPage({ history });
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderedExpenses: orderedExpensesSelector(state),
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  loading: loadingSelector(state),
  hasActiveSubscription: hasActiveSubscriptionSelector(state),
  expensesYear: expensesYearSelector(state),
  userStatus: _.get(accountDetailsSelector(state), 'status'),
  hasFiltersSelected: hasFiltersSelectedSelector(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state),
  isPremiumSubscriber: isPremiumSubscriberSelector(state)
});

const mapDispatchToProps = {
  getInitialExpenses,
  getExpenseWaiting,
  getRules,
  setExpenseReviewModalType,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage,
  setExpensesYear,
  getBankSummaryV2
};

const ConnectedExpenseReviewExpenses = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewExpenses);

export default ConnectedExpenseReviewExpenses;
