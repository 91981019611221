import _ from 'lodash';
import { CATEGORY_TYPE_TAXFLOW_FORM_DATE, CATEGORY_TYPE_TAXFLOW_FORM_SSN } from '@app/src/constants/constants';
import { trackActivity } from '@app/src/services/analyticsService';
import { isTooLarge, isTooShort } from '@app/src/services/taxFlow/helpers';
import {
  isStreetInvalid,
  isCityInvalid,
  isIncomeYearInvalid,
  isSubQuestionDateInvalid,
  isDateBefore1900OrAfterNow,
  isSsnInvalid,
  isDateBefore1900OrAfterTaxYear,
  isChildCareIDInvalid,
  isNYCountyInvalid
} from '@app/src/services/taxFlow/isValidInput';
import { CAR_SLUGS } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__CREDIT_CHILD_CARE_FEDERAL_ID,
  SLUG__CREDIT_CHILD_CARE_WHO,
  SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { HOME_SLUGS } from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR,
  SLUG__INCOME_RETIREMENT_PENSION_INFO,
  SLUG__INCOME_RETIREMENT_PENSION_ROTH,
  SLUG__INCOME_UNEMPLOYMENT_INFO,
  SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET,
  SLUG__INCOME_FREELANCE_AGI,
  SLUG__INCOME_W2_INFO,
  SLUG__INCOME_W2_STATE,
  SLUG__INCOME_W2_STATE_EMPLOYER_ID,
  SLUG__INCOME_W2_STATE_2,
  SLUG__INCOME_W2_STATE_EMPLOYER_ID_2
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_STREET,
  SLUG__HOME_ADDRESS_CITY,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  SLUG__HOME_ADDRESS_STATE,
  SLUG__HOME_ADDRESS_STATE_MULTISTATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_INCOME,
  SLUG__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__STATE_EXPENSES,
  SLUG__NY_COUNTY
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_SIGNATURE_SELF,
  SLUG__SUBMIT_SIGNATURE_SPOUSE
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { isSignatureInvalid } from '@app/src/utils/taxValidationUtils';

export const showWarningMessage = ({ question, subquestion, answer, subanswer, props }) => {
  if (_.get(subquestion, ['question_meta', 'minLength'])) {
    if (isTooShort({ question: subquestion, answer: subanswer })) {
      return true;
    }
  }

  if (_.get(subquestion, ['question_meta', 'maxValue'])) {
    if (isTooLarge({ question: subquestion, answer: subanswer })) {
      return true;
    }
  }

  const collectionErrors = _.filter(_.get(props, 'errors') || [], {
    coll_type: _.get(subquestion, 'collectionType', _.get(question, 'collectionType')),
    coll_id: _.get(props, 'collectionId')
  });

  if (
    _.some(collectionErrors, {
      slug: _.get(subquestion, 'endpoint_attr')
    })
  ) {
    return true;
  }

  if (question.slug === SLUG__STATE_RETURN && props && props.queryResults) {
    const firstStateReturn = props.queryResults.find((result) => {
      return result.coll_type === COLLECTION_TYPE__STATE_RETURN && result.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN;
    });
    return firstStateReturn && _.get(answer, ['value']) === _.get(firstStateReturn, ['answer', 'value']);
  } else if (
    (question.slug === SLUG__STATE_INCOME || question.slug === SLUG__STATE_EXPENSES) &&
    props &&
    props.slug1 &&
    props.slug2
  ) {
    const percentage1 = _.get(answer, ['value', props.slug1]);
    const percentage2 = _.get(answer, ['value', props.slug2]);
    if (percentage1.value === '' || percentage2.value === '') {
      return false;
    } else if (parseInt(percentage1.value, 10) + parseInt(percentage2.value, 10) !== 100) {
      return true;
    }
  } else if (_.get(subquestion, ['slug']) === SLUG__CREDIT_CHILD_CARE_WHO && props && props.addDependent) {
    return (
      answer.value &&
      answer.value[SLUG__CREDIT_CHILD_CARE_WHO] &&
      props &&
      !props.queryResults.some((e) => e.slug === ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME) &&
      props.addDependent
    );
  } else if (
    _.get(subquestion, 'slug') === SLUG__CREDIT_CHILD_CARE_WHO &&
    props &&
    !props.addDependent &&
    collectionErrors
  ) {
    return (
      _.find(collectionErrors, { slug: subquestion.endpoint_attr }) &&
      props.queryResults.some((e) => e.slug === ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME)
    );
  } else if (
    _.get(subquestion, ['slug']) === SLUG__CREDIT_CHILD_CARE_FEDERAL_ID &&
    props &&
    props.queryResults &&
    _.get(answer, ['value', SLUG__CREDIT_CHILD_CARE_FEDERAL_ID, 'value'])
  ) {
    return isChildCareIDInvalid({ question, answer, queryResults: props.queryResults });
  } else if (_.get(subquestion, 'slug') === SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS && answer.value) {
    return _.isEmpty(_.get(answer, ['value', SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS, 'value']));
  } else if (question.slug === CAR_SLUGS.SERVICE_DATE && answer.value) {
    return isDateBefore1900OrAfterTaxYear({ answer });
  } else if ((question.slug === CAR_SLUGS.PURCHASE_DATE || question.slug === HOME_SLUGS.ACQUIRED) && answer.value) {
    return isDateBefore1900OrAfterNow({ answer });
  } else if (_.get(subquestion, 'question_type') === CATEGORY_TYPE_TAXFLOW_FORM_DATE) {
    return isSubQuestionDateInvalid({ subquestion, subanswer, answer, queryResults: props.queryResults });
  } else if (_.get(subquestion, 'question_type') === CATEGORY_TYPE_TAXFLOW_FORM_SSN) {
    return isSsnInvalid({ answer: subanswer });
  } else if (question.slug === SLUG__HOME_ADDRESS_DETAIL) {
    if (
      _.get(subquestion, ['slug']) === SLUG__HOME_ADDRESS_STREET &&
      _.get(answer, ['value', SLUG__HOME_ADDRESS_STREET, 'value'])
    ) {
      return isStreetInvalid({
        answer: _.get(answer, ['value', SLUG__HOME_ADDRESS_STREET])
      });
    } else if (_.get(subquestion, ['slug']) === SLUG__HOME_ADDRESS_STATE_MULTISTATE) {
      if (
        _.get(answer, ['value', SLUG__HOME_ADDRESS_STATE, 'value']) ===
          _.get(answer, ['value', SLUG__HOME_ADDRESS_STATE_MULTISTATE, 'value']) &&
        _.get(answer, ['value', SLUG__HOME_ADDRESS_STATE_MULTISTATE, 'value'])
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      _.get(subquestion, ['slug']) === SLUG__HOME_ADDRESS_CITY &&
      _.get(answer, ['value', SLUG__HOME_ADDRESS_CITY, 'value'])
    ) {
      return isCityInvalid({
        answer: _.get(answer, ['value', SLUG__HOME_ADDRESS_CITY])
      });
    } else {
      return false;
    }
  } else if (question.slug === SLUG__INCOME_RETIREMENT_PENSION_INFO) {
    if (
      _.get(subquestion, ['slug']) === SLUG__INCOME_RETIREMENT_PENSION_ROTH &&
      _.get(answer, ['value', SLUG__INCOME_RETIREMENT_PENSION_ROTH, 'value'])
    ) {
      return isIncomeYearInvalid({
        answer: _.get(answer, ['value', SLUG__INCOME_RETIREMENT_PENSION_ROTH])
      });
    } else {
      return false;
    }
  } else if (question.slug === SLUG__INCOME_UNEMPLOYMENT_INFO) {
    if (
      _.get(subquestion, ['slug']) === SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR &&
      _.get(answer, ['value', SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR, 'value'])
    ) {
      return isIncomeYearInvalid({
        answer: _.get(answer, ['value', SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR])
      });
    } else if (
      _.get(subquestion, ['slug']) === SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET &&
      _.get(answer, ['value', SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET, 'value'])
    ) {
      return isStreetInvalid({
        answer: _.get(answer, ['value', SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET])
      });
    } else {
      return false;
    }
  } else if (question.slug === SLUG__INCOME_W2_INFO && props) {
    const subQuestionSlug = _.get(subquestion, 'slug', '');
    if (subQuestionSlug.startsWith('income-w2-twelve') || subQuestionSlug.startsWith('income-w2-fourteen')) {
      return collectionErrors.some((error) => error.slug === subQuestionSlug && error.code === 'invalid_format');
    } else if (
      subQuestionSlug === SLUG__INCOME_W2_STATE ||
      subQuestionSlug === SLUG__INCOME_W2_STATE_EMPLOYER_ID ||
      SLUG__INCOME_W2_STATE_2 ||
      SLUG__INCOME_W2_STATE_EMPLOYER_ID_2
    ) {
      return collectionErrors.some(
        (error) => error.slug === subQuestionSlug && (error.code === 'missing' || error.code === 'invalid form')
      );
    }
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE && _.get(props, 'queryResults')) {
    const status = _.get(
      getQueryResultByEndpointAttribute({
        queryResults: props.queryResults,
        collectionType: COLLECTION_TYPE__SELF,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
      }),
      ['answer', 'value']
    );
    if (
      _.get(subquestion, ['slug']) === SLUG__SUBMIT_SIGNATURE_SELF &&
      _.get(answer, ['value', SLUG__SUBMIT_SIGNATURE_SELF, 'value'])
    ) {
      return isSignatureInvalid({ question: subquestion, answer: subanswer, status, queryResults: props.queryResults });
    } else if (
      _.get(subquestion, ['slug']) === SLUG__SUBMIT_SIGNATURE_SPOUSE &&
      _.get(answer, ['value', SLUG__SUBMIT_SIGNATURE_SPOUSE, 'value'])
    ) {
      return isSignatureInvalid({ question: subquestion, answer: subanswer, status, queryResults: props.queryResults });
    } else {
      return false;
    }
  } else if (question.slug === SLUG__INCOME_FREELANCE_AGI) {
    return parseInt(_.get(answer, 'value'), 10) === 0;
  } else if (question.slug === SLUG__NY_COUNTY) {
    return isNYCountyInvalid({
      queryResults: _.get(props, 'queryResults'),
      allQuestions: _.get(props, 'allQuestions'),
      currentCounty: _.get(answer, 'value')
    });
  } else {
    return false;
  }
};

export const trackWarningShown = ({ question, answer }) => {
  trackActivity('question: warning shown', {
    flow: question.flow,
    question: question.slug,
    title: question.title,
    answer: answer.value
  });
};
