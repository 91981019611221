import React from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { formUploadCollectionTypesSelector, uploadErrorSelector } from '@app/src/selectors/taxFlowSelectors';
import {
  TAXFLOW__BULK_UPLOAD_FAILED_HEADER,
  TAXFLOW__BULK_UPLOAD_UNSUPPORTED_HEADER,
  TAXFLOW__FORM_UPLOAD_FAILED_HEADER
} from '@app/src/taxflow/collection/constants/formUploadConstants';
import { hideCollectionFormUploadErrorModal } from '@app/src/taxflow/collection/services/collectionService';
import { getFormUploadErrorMessage } from '@app/src/taxflow/collection/utils/collectionUtils';
import {
  flattenedUploadAttemptsSelector,
  formUploadModalHeaderSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { isPremiumSubscriberSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { COLLECTION_TYPE__SPECIAL } from '@app/src/taxflow/sections/special/constants/specialConstants';
import '@app/src/taxflow/collection/components/TaxFlowCollectionFormUploadErrorModal.scss';

const UnsupportedFormMessage = ({ isPremiumSubscriber, errorMessage }) => {
  if (isPremiumSubscriber) {
    return (
      <p>
        Please email this form to your dedicated tax assistant or{' '}
        <a href='mailto:support@keepertax.com'>support@keepertax.com</a> to have it included in your tax return.
      </p>
    );
  }

  if (errorMessage === 'Form not supported by Keeper') {
    return (
      <>
        <p>Unfortunately, the basic subscription doesn't support filing for:</p>
        <ul>
          <li>Rental property income</li>
          <li>Income from a foreign country</li>
          <li>K-1 (partnership) income</li>
          <li>1099-C (cancellation of debt)</li>
        </ul>
        <p>
          Please reach out to <a href='mailto:support@keepertax.com'>support@keepertax.com</a> or your assigned tax
          assistant to upgrade to the Premium subscription to have a dedicated tax pro add any of these complex
          situations to your tax return.
        </p>
      </>
    );
  }

  return (
    <>
      <p>Unfortunately, Keeper currently doesn't support filing for:</p>
      <ul>
        <li>Rental property income</li>
        <li>Income from a foreign country</li>
        <li>K-1 (partnership) income</li>
        <li>1099-C (cancellation of debt)</li>
      </ul>
      <p>
        Please reach out to <a href='mailto:support@keepertax.com'>support@keepertax.com</a> or your assigned tax
        assistant for a partial refund if you have an unsupported form.
      </p>
    </>
  );
};
const TaxFlowCollectionFormUploadErrorModal = ({
  collectionFormUploadErrorModalCollectionType,
  collectionFormUploadErrorModalAttemptId,
  header,
  uploadError,
  flattenedUploadAttempts,
  isPremiumSubscriber,
  hideCollectionFormUploadErrorModal,
  formUploadCollectionTypes
}) => {
  const onClose = () => {
    hideCollectionFormUploadErrorModal({
      collectionType: collectionFormUploadErrorModalCollectionType
    });
  };

  const defaultHeader =
    collectionFormUploadErrorModalCollectionType === COLLECTION_TYPE__SPECIAL
      ? TAXFLOW__BULK_UPLOAD_FAILED_HEADER
      : TAXFLOW__FORM_UPLOAD_FAILED_HEADER;

  const errorMessage = flattenedUploadAttempts.find(
    (attempt) => attempt.id === collectionFormUploadErrorModalAttemptId
  )?.errorMessage;

  const errorBody =
    collectionFormUploadErrorModalCollectionType === COLLECTION_TYPE__SPECIAL
      ? uploadError || 'Please try again or enter the information manually.'
      : getFormUploadErrorMessage({ errorMessage });

  const failedAttemptRecord =
    collectionFormUploadErrorModalAttemptId &&
    flattenedUploadAttempts.find((a) => a.id === collectionFormUploadErrorModalAttemptId);

  const isUnsupportedForm =
    _.get(failedAttemptRecord, 'collectionType') &&
    !formUploadCollectionTypes.includes(failedAttemptRecord.collectionType);

  return (
    <Dialog className={'taxflow-collection-form-upload-error-modal'} onClose={onClose} open>
      <DialogTitle className={'form-upload-modal-header'}>
        <h1 className={'form-upload-modal-header-title'}>
          {header || (isUnsupportedForm ? TAXFLOW__BULK_UPLOAD_UNSUPPORTED_HEADER : defaultHeader)}
        </h1>
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className='steps-head'>
        <DialogContentText className='step-description'>
          <p>{errorBody}</p>
          {isUnsupportedForm && (
            <div>
              <UnsupportedFormMessage
                isPremiumSubscriber={isPremiumSubscriber}
                errorMessage={errorMessage}
              ></UnsupportedFormMessage>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  collectionFormUploadErrorModalCollectionType: _.get(state, [
    'taxFlowModals',
    'collectionFormUploadErrorModalCollectionType'
  ]),
  collectionFormUploadErrorModalAttemptId: _.get(state, ['taxFlowModals', 'collectionFormUploadErrorModalAttemptId']),
  header: formUploadModalHeaderSelector(state),
  uploadError: uploadErrorSelector(state),
  flattenedUploadAttempts: flattenedUploadAttemptsSelector(state),
  isPremiumSubscriber: isPremiumSubscriberSelector(state),
  formUploadCollectionTypes: formUploadCollectionTypesSelector(state)
});

const mapDispatchToProps = {
  hideCollectionFormUploadErrorModal
};

const ConnectedTaxFlowCollectionFormUploadErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowCollectionFormUploadErrorModal);

export default ConnectedTaxFlowCollectionFormUploadErrorModal;
