import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSelector } from 'reselect';
import { DEFAULT_API_OPTIONS } from '@app/src/api/constants';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { serverUrl } from '@app/src/global/Environment';

const baseUrl = serverUrl();

const TAG__WORK_INFO = 'WorkInfo';
export const TAG__CAMPAIGN = 'Campaign';
export const TAG__FEATURES = 'TAG__FEATURES';

const profileApi = createApi({
  ...DEFAULT_API_OPTIONS,
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}api/profile/`,
    prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
  }),
  tagTypes: [TAG__WORK_INFO, TAG__CAMPAIGN, TAG__FEATURES],
  endpoints: (builder) => ({
    getWorkInfo: builder.query({
      query: () => ({
        url: 'workinfo',
        method: 'GET'
      }),
      providesTags: [TAG__WORK_INFO],
      transformResponse: (response) => response.data
    }),
    getCampaign: builder.query({
      query: () => ({
        url: 'get-campaign',
        method: 'GET'
      }),
      providesTags: [TAG__CAMPAIGN],
      transformResponse: (response) => response.data.campaign
    }),
    updateCampaign: builder.mutation({
      query: ({ campaign }) => ({
        url: 'update-campaign',
        method: 'POST',
        body: { campaign }
      }),
      invalidatesTags: [TAG__CAMPAIGN]
    }),
    getFeatures: builder.query({
      query: () => ({
        url: 'features',
        method: 'GET'
      }),
      providesTags: [TAG__FEATURES],
      transformResponse: (response) => response.data.features
    })
  })
});

export default profileApi;

export const { useGetWorkInfoQuery, useGetCampaignQuery, useUpdateCampaignMutation, useGetFeaturesQuery } = profileApi;

// Actions

export const getCampaign = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getCampaign.initiate, dispatch });

export const updateCampaign =
  ({ campaign }) =>
  async (dispatch) => {
    await dispatch(profileApi.endpoints.updateCampaign.initiate({ campaign }));
  };

export const getFeatures = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getFeatures.initiate, dispatch });

// Selectors
export const campaignSelector = createSelector(
  [profileApi.endpoints.getCampaign.select()],
  ({ data: campaign }) => campaign
);

export const featuresSelector = createSelector(
  [profileApi.endpoints.getFeatures.select()],
  ({ data: features }) => features || []
);
