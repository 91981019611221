import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { ArrowDown2 } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDebounce, useWindowSize } from 'react-use';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxFlowProgressBar from '@app/src/Components/TaxFlow/Common/TaxFlowProgressBar';
import TaxFlowAcceptedByIrsElement from '@app/src/Components/TaxFlow/Question/TaxFlowAcceptedByIrsElement';
import TaxFlowBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowBillElement';
import TaxFlowBulkUploadItem from '@app/src/Components/TaxFlow/Question/TaxFlowBulkUploadItem';
import TaxFlowFiledWithIrsElement from '@app/src/Components/TaxFlow/Question/TaxFlowFiledWithIrsElement';
import TaxFlowQuestionFooter from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter';
import TaxFlowQuestionnaireSummary from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionnaireSummary';
import TaxFlowReviewPillsSection from '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection';
import TaxFlowSubmitProgressElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressElement';
import TaxFlowSubmitToIrsElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitToIrsElement';
import TaxFlowSubmitWarnings from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitWarnings';
import { resetCurrentQuestion } from '@app/src/actions/taxFlowActions';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import {
  useLazyGetSsnMatchedQuery,
  useExecuteSsnMatchedCheckMutation,
  useLazyGetIdVerificationResultQuery,
  useLazyGetTaxAmountsQuery
} from '@app/src/api/taxDataApi';
import LockIcon from '@app/src/assets/lock.svg?react';
import MailIcon from '@app/src/assets/mail.svg?react';
import { userSelector } from '@app/src/selectors/userSelectors';
import { HomeSkeleton } from '@app/src/taxflow/main/components/TaxFlowHomeSkeleton';
import { useHomeAnalytics } from '@app/src/taxflow/main/hooks/useHomeAnalytics';
import { useSubstitutedText } from '@app/src/taxflow/main/hooks/useSubstitutedText';
import { useUIState } from '@app/src/taxflow/main/hooks/useUIState';
import { useLazyUserNeedsToPay } from '@app/src/taxflow/main/hooks/useUserNeedsToPay';
import { postTaxSubmissionAndUpdateUiStage } from '@app/src/taxflow/main/services/mainService';
import TaxFlowSubscriptionPrompt from '@app/src/taxflow/navigation/components/TaxFlowSubscriptionPrompt';
import { PATH_COMPONENT__HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO,
  PATH_COMPONENT__SUBMIT_DEBIT,
  PATH_COMPONENT__SUBMIT_EMAIL_INFO,
  PATH_COMPONENT__SUBMIT_PAY_NOW
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  TAXFLOW_BASE_URL,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/taxflow/main/components/TaxFlowHome.scss';

export const TaxFlowHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 1024;

  const { data: campaign, isLoading: campaignLoading } = useGetCampaignQuery();
  const { uiState, isLoading: uiStateLoading } = useUIState();
  const [getSsnMatched] = useLazyGetSsnMatchedQuery();
  const [getIdVerificationResult] = useLazyGetIdVerificationResultQuery();
  const [executeSsnMatchedCheck] = useExecuteSsnMatchedCheckMutation();
  const [getUserNeedsToPay] = useLazyUserNeedsToPay();
  const [getTaxAmounts] = useLazyGetTaxAmountsQuery();

  const user = useSelector(userSelector);

  const [navigationBarLoading, setNavigationBarLoading] = useState(false);
  const [submitWarningsLoading, setSubmitWarningsLoading] = useState(false);
  const [bulkUploadLoading, setBulkUploadLoading] = useState(false);
  const [questionnaireSummaryLoading, setQuestionnaireSummaryLoading] = useState(false);
  const [submitProgressLoading, setSubmitProgressLoading] = useState(false);
  const [billElementLoading, setBillElementLoading] = useState(false);
  const [submitToIRSElementLoading, setSubmitToIRSElementLoading] = useState(false);
  const [filedWithIRSElementLoading, setFiledWithIRSElementLoading] = useState(false);
  const [acceptedByIRSElementLoading, setAcceptedByIRSElementLoading] = useState(false);
  const [reviewPillsSectionLoading, setReviewPillsSectionLoading] = useState(false);

  const [debouncedLoading, setDebouncedLoading] = useState(true);

  const { isLoading: titleLoading, substitutedText: title } = useSubstitutedText({ text: uiState.title });
  const { isLoading: subtitleLoading, substitutedText: maybeSubtitle } = useSubstitutedText({
    text: uiState.maybeSubTitle
  });

  const bulkTitle = _.chain(uiState).get('homeSections').find({ slug: 'bulk-upload' }).get('title').value();
  const bulkCollapsed = _.chain(uiState).get('homeSections').find({ slug: 'bulk-upload' }).get('collapsed').value();
  const showBulkUpload = !_.chain(uiState).get('homeSections').find({ slug: 'bulk-upload' }).get('hidden').value();
  const bulkVariant = debouncedLoading
    ? 'default'
    : _.chain(uiState).get('homeSections').find({ slug: 'bulk-upload' }).get('variant').value();
  const questionnaireTitle = _.chain(uiState).get('homeSections').find({ slug: 'questionnaire' }).get('title').value();
  const questionnaireCollapsed = _.chain(uiState)
    .get('homeSections')
    .find({ slug: 'questionnaire' })
    .get('collapsed')
    .value();
  const showQuestionnaire = !_.chain(uiState).get('homeSections').find({ slug: 'questionnaire' }).get('hidden').value();
  const questionnaireVariant = debouncedLoading
    ? 'default'
    : _.chain(uiState).get('homeSections').find({ slug: 'questionnaire' }).get('variant').value();

  const questionnaireDisabledText = _.chain(uiState)
    .get('homeSections')
    .find({ slug: 'questionnaire' })
    .get('disabledText')
    .value();
  const uiStage = campaign?.ui_stage;
  const reviewTitle = _.chain(uiState).get('homeSections').find({ slug: 'review' }).get('title').value();
  const reviewVariant = debouncedLoading
    ? 'default'
    : _.chain(uiState).get('homeSections').find({ slug: 'review' }).get('variant').value();
  const reviewDisabledText = _.chain(uiState).get('homeSections').find({ slug: 'review' }).get('disabledText').value();
  const showReviewPills = !_.chain(uiState).get('homeSections').find({ slug: 'review' }).get('hidden').value();
  const submitButtonEnabled = uiState.submitButtonEnabled;
  const submitButtonText = uiState.submitButtonText;
  const showSubmitProgress = uiState.showSubmitProgress;
  const showTaxBill = uiState.showTaxBill;
  const showSubmitButton = uiState.showSubmitButton;
  const showSubmitWarnings = uiState.showSubmitWarnings;
  const showSubmitToIRSElement = uiState.showSubmitToIRSElement;
  const showFiledWithIRSElement = uiState.showFiledWithIRSElement;
  const showAcceptedByIRSElement = uiState.showAcceptedByIRSElement;
  const showEmailPill = uiState.showEmailPill;

  const isLoading =
    uiStateLoading ||
    campaignLoading ||
    navigationBarLoading ||
    titleLoading ||
    subtitleLoading ||
    (submitWarningsLoading && showSubmitWarnings) ||
    (bulkUploadLoading && showBulkUpload) ||
    (questionnaireSummaryLoading && showQuestionnaire) ||
    (submitProgressLoading && showSubmitProgress) ||
    (billElementLoading && showTaxBill) ||
    (submitToIRSElementLoading && showSubmitToIRSElement) ||
    (filedWithIRSElementLoading && showFiledWithIRSElement) ||
    (acceptedByIRSElementLoading && showAcceptedByIRSElement) ||
    (showReviewPills && reviewPillsSectionLoading);

  useDebounce(() => setDebouncedLoading(isLoading), 200, [isLoading]);

  useHomeAnalytics({ isLoading: debouncedLoading });

  // Upon load, reset the current question - tax home lies outside of the "TaxFlowQuestion" model - thus we shouldn't track a question in store
  // while on the home page
  useEffect(() => {
    dispatch(resetCurrentQuestion());
  }, [dispatch]);

  const onSubmit = () => {
    if (_.isNil(uiStage) || _.isEmpty(uiStage) || _.some(UI_STAGE_METADATA, { stage: uiStage, preSubmit: true })) {
      submitTaxes();
    } else if (uiStage === UI_STAGE__CONFIRM_AMOUNTS) {
      progressToConfirmAmountsFlow();
    }
  };

  const submitTaxes = async () => {
    const [{ data: idVerificationResult }, { data: ssnMatched }] = await Promise.all([
      getIdVerificationResult(),
      getSsnMatched()
    ]);
    if (idVerificationResult === 'pass') {
      dispatch(postTaxSubmissionAndUpdateUiStage({}));
      history.replace(`${TAXFLOW_BASE_URL}/${PATH_COMPONENT__HOME}`);
      return;
    }
    if (ssnMatched) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO}`);
      return;
    }

    const { data: match } = await executeSsnMatchedCheck();
    if (match) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO}`);
      return;
    }
  };

  const progressToConfirmAmountsFlow = async () => {
    const taxAmounts = await getTaxAmounts();
    const allMultistateZero = taxAmounts?.multistate?.every(({ amount }) => amount === 0) ?? true;
    const zeroTaxOwed = Number(taxAmounts?.federal_amount) === 0 && allMultistateZero;
    if (zeroTaxOwed) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_EMAIL_INFO}`);
      return;
    }

    const userNeedsToPay = await getUserNeedsToPay();
    if (userNeedsToPay) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_PAY_NOW}`);
      return;
    }

    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_DEBIT}`);
  };

  return (
    <div className='wrapper-view'>
      <div className={classNames('steps-content-wrapper', 'steps-wrapper')}>
        <div
          className={classNames('steps-content', {
            'steps-content-mobile': isMobile
          })}
        >
          <div className='steps-body-wrapper-large'>
            <Fade
              in={true}
              style={{
                transitionDelay: '200ms'
              }}
            >
              <div>
                {debouncedLoading && (
                  <div className='taxflow-home'>
                    <HomeSkeleton />
                  </div>
                )}
                <div className={classNames('taxflow-home', { 'taxflow-home-hidden': debouncedLoading })}>
                  <TaxFlowProgressBar setLoading={setNavigationBarLoading} />
                  <div className='taxflow-home-title'>{title}</div>
                  {showEmailPill && (
                    <TaxFlowPill
                      text={user.email}
                      icon={
                        <Icon className='taxflow-chip-icon'>
                          <MailIcon width={16} height={16} />
                        </Icon>
                      }
                    />
                  )}
                  {maybeSubtitle}
                  {showSubmitWarnings && <TaxFlowSubmitWarnings setLoading={setSubmitWarningsLoading} />}
                  <>
                    {showBulkUpload && (
                      <TaxFlowHomeSection title={bulkTitle} variant={bulkVariant} collapsed={bulkCollapsed}>
                        <TaxFlowBulkUploadItem setLoading={setBulkUploadLoading} />
                      </TaxFlowHomeSection>
                    )}
                    {showQuestionnaire && (
                      <TaxFlowHomeSection
                        title={questionnaireTitle}
                        collapsed={questionnaireCollapsed}
                        variant={questionnaireVariant}
                        disabledText={questionnaireDisabledText}
                      >
                        <TaxFlowQuestionnaireSummary setLoading={setQuestionnaireSummaryLoading} />
                      </TaxFlowHomeSection>
                    )}
                    {showSubmitProgress && <TaxFlowSubmitProgressElement setLoading={setSubmitProgressLoading} />}
                    {showTaxBill && <TaxFlowBillElement setLoading={setBillElementLoading} />}
                    {showSubmitToIRSElement && <TaxFlowSubmitToIrsElement setLoading={setSubmitToIRSElementLoading} />}
                    {showFiledWithIRSElement && (
                      <TaxFlowFiledWithIrsElement setLoading={setFiledWithIRSElementLoading} />
                    )}
                    {showAcceptedByIRSElement && (
                      <TaxFlowAcceptedByIrsElement setLoading={setAcceptedByIRSElementLoading} />
                    )}
                    {showReviewPills && (
                      <TaxFlowHomeSection title={reviewTitle} variant={reviewVariant} disabledText={reviewDisabledText}>
                        <TaxFlowReviewPillsSection setLoading={setReviewPillsSectionLoading} />
                      </TaxFlowHomeSection>
                    )}
                    {showSubmitButton && (
                      <Button
                        fullWidth
                        size='large'
                        variant='contained'
                        disabled={!submitButtonEnabled}
                        style={{ marginTop: 8 }}
                        onClick={onSubmit}
                      >
                        <div>{submitButtonText}</div>
                      </Button>
                    )}
                    <TaxFlowSubscriptionPrompt />
                  </>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {isMobile && <TaxFlowQuestionFooter />}
    </div>
  );
};

const TaxFlowHomeSection = ({ children, title, collapsed, variant = 'default', disabledText }) => {
  const [open, setOpen] = useState(!collapsed);
  const { width } = useWindowSize();
  const isSmallScreen = width < 1279;

  useEffect(() => {
    setOpen(!collapsed);
  }, [collapsed]);

  return (
    <div className={classNames('taxflow-home-section', { 'taxflow-home-section-disabled': variant === 'disabled' })}>
      <div
        className='taxflow-home-section-title'
        onClick={() => {
          variant === 'collapsible' && setOpen(!open);
        }}
      >
        <div>{title}</div>
        {variant === 'collapsible' && (
          <IconButton
            sx={{
              ...(!open ? { transform: 'scaleY(-1)' } : {}),
              transition: 'transform 0.3s'
            }}
          >
            <ArrowDown2 width={16} height={16} stroke={'black'} />
          </IconButton>
        )}
        {variant === 'disabled' && (
          <div className='taxflow-home-section-title-disabled-rhs'>
            {!isSmallScreen && <div>{disabledText}</div>}
            <LockIcon />
          </div>
        )}
        {variant === 'loading' && <CircularProgress size={24} color='success' />}
      </div>
      {!(variant === 'disabled') && (
        <Collapse in={open} style={{ margin: 0 }}>
          {<div className='taxflow-home-section-body'>{children}</div>}
        </Collapse>
      )}
    </div>
  );
};
