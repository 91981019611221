import _ from 'lodash';
import { isReactNative } from '@app/src/global/Helpers';
import { COLLECTION_TYPE__CAR, CAR_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/car/carConstants';
import {
  COLLECTION_TYPE__CREDIT_CAPITAL_LOSS,
  COLLECTION_TYPE__CREDIT_CHARITY,
  COLLECTION_TYPE__CREDIT_CHILD_CARE,
  COLLECTION_TYPE__CREDIT_COGS,
  COLLECTION_TYPE__CREDIT_EARNED_INCOME,
  COLLECTION_TYPE__CREDIT_HEALTHCARE,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  COLLECTION_TYPE__CREDIT_HSA,
  COLLECTION_TYPE__CREDIT_IRA,
  COLLECTION_TYPE__CREDIT_QUARTERLY_TAX,
  COLLECTION_TYPE__CREDIT_STANDARD,
  COLLECTION_TYPE__CREDIT_STUDENT_LOANS,
  COLLECTION_TYPE__CREDIT_STUDENT_TUITION,
  ENDPOINT_ATTRIBUTE__CREDIT_BUSINESS_LOANS_INTEREST,
  ENDPOINT_ATTRIBUTE__CREDIT_CAPITAL_LOSS_DONE,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
  ENDPOINT_ATTRIBUTE__CREDIT_CHILD_CARE_PROVIDER_NAME,
  ENDPOINT_ATTRIBUTE__CREDIT_COGS,
  ENDPOINT_ATTRIBUTE__CREDIT_EARNED_INCOME_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_HSA_CONTRIBUTION,
  ENDPOINT_ATTRIBUTE__CREDIT_IRA_CONTRIBUTION,
  ENDPOINT_ATTRIBUTE__CREDIT_QUARTERLY_TAX_DONE,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION,
  ENDPOINT_ATTRIBUTE__CREDIT_STUDENT_LOANS_LENDER,
  ENDPOINT_ATTRIBUTE__CREDIT_STUDENT_TUITION_SCHOOL
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES
} from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  COLLECTION_TYPE__INCOME_1099G,
  COLLECTION_TYPE__INCOME_DIV,
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INTEREST,
  COLLECTION_TYPE__INCOME_INVEST,
  COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
  COLLECTION_TYPE__INCOME_RETIREMENT,
  COLLECTION_TYPE__INCOME_W2,
  COLLECTION_TYPE__INCOME_W2G,
  ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION,
  ENDPOINT_ATTRIBUTE__INCOME_1099G_PAYER_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY,
  ENDPOINT_ATTRIBUTE__INCOME_DIV_PAYER_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2,
  ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_INTEREST_PAYER_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_DESCRIPTION,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS,
  ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_DESCRIPTION,
  ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET,
  ENDPOINT_ATTRIBUTE__INCOME_W2G_PAYER_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS,
  ENDPOINT_ATTRIBUTE__INCOME_W2_EMPLOYER,
  ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES,
  ENDPOINT_ATTRIBUTE__INCOME_INTEREST_GOVERNMENT_INTEREST
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  COLLECTION_TYPE__HOME_ADDRESS,
  COLLECTION_TYPE__SELF,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__DEPENDENT_LAST_NAME,
  ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SELF_LAST_NAME,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME,
  SLUG__HOME_ADDRESS_STREET
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { PATH_COMPONENT__HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__SUBMIT_DEBIT,
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_EXIT_BOUNCE,
  PATH_COMPONENT__SUBMIT_EXIT_BOUNCE,
  SLUG__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_PIN,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_SWITCH_TO_ANNUAL,
  SLUG__SUBMIT_PAY_NOW,
  PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL,
  PATH_COMPONENT__SUBMIT_PAY_NOW,
  PATH_COMPONENT__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_SIGNATURE,
  COLLECTION_TYPE__SUBMIT_SIGNATURE,
  PATH_COMPONENT__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_EMAIL_INFO,
  PATH_COMPONENT__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_PAY_TEST,
  PATH_COMPONENT__SUBMIT_PAY_TEST,
  PATH_COMPONENT__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_BANK_NUMBERS,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID,
  SLUG__SUBMIT_CONFIRM_ID,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO,
  SLUG__SUBMIT_CONFIRM_ID_INTRO
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__SUBMIT_SWITCH_TO_ANNUAL]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_EMAIL_INFO,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_EMAIL_INFO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_SIGNATURE,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRMATION]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRMATION,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRM_ID_INTRO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRM_ID,
      questionnaireEarlyExit: true
    }
  };
};

export const getNextPathComponent = ({ question, paid, answer, irsPaymentAccount, idVerificationResult }) => {
  let nextPathComponent = null;

  if (question.slug === SLUG__SUBMIT_CONFIRM_ID && idVerificationResult === 'pass') {
    nextPathComponent = PATH_COMPONENT__HOME;
  } else if (question.slug === SLUG__SUBMIT_CONFIRM_ID && idVerificationResult === 'fail') {
    nextPathComponent = PATH_COMPONENT__HOME;
  } else if (
    question.slug === SLUG__SUBMIT_DEBIT ||
    question.slug === SLUG__SUBMIT_DEBIT_MANUAL ||
    question.slug === SLUG__SUBMIT_BANK_NUMBERS
  ) {
    const chaseAccountSelected = _.get(irsPaymentAccount, 'institution_id') === 'ins_56';

    if (_.get(answer, 'value') === 'ach' && chaseAccountSelected) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_BANK_NUMBERS;
    } else {
      nextPathComponent = getPaymentPathComponent({ paid });
    }
  } else if (question.slug === SLUG__SUBMIT_PAY_NOW) {
    if (paid) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    } else {
      nextPathComponent = PATH_COMPONENT__SUBMIT_PAY_NOW;
    }
  } else if (question.slug === SLUG__SUBMIT_PAY_TEST) {
    if (paid) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    } else {
      nextPathComponent = PATH_COMPONENT__SUBMIT_PAY_TEST;
    }
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE) {
    nextPathComponent = PATH_COMPONENT__SUBMIT_CONFIRMATION;
  }
  return { nextPathComponent };
};

export const getPaymentPathComponent = ({ paid }) => {
  if (paid) {
    return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
  } else if (isReactNative()) {
    return PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL;
  } else {
    return PATH_COMPONENT__SUBMIT_PAY_NOW;
  }
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SUBMIT_PIN]: SLUG__SUBMIT_PIN,
    [PATH_COMPONENT__SUBMIT_DEBIT]: SLUG__SUBMIT_DEBIT,
    [PATH_COMPONENT__SUBMIT_DEBIT_MANUAL]: SLUG__SUBMIT_DEBIT_MANUAL,
    [PATH_COMPONENT__SUBMIT_BANK_NUMBERS]: SLUG__SUBMIT_BANK_NUMBERS,
    [PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL]: SLUG__SUBMIT_SWITCH_TO_ANNUAL,
    [PATH_COMPONENT__SUBMIT_PAY_NOW]: SLUG__SUBMIT_PAY_NOW,
    [PATH_COMPONENT__SUBMIT_PAY_TEST]: SLUG__SUBMIT_PAY_TEST,
    [PATH_COMPONENT__SUBMIT_EMAIL_INFO]: SLUG__SUBMIT_EMAIL_INFO,
    [PATH_COMPONENT__SUBMIT_SIGNATURE]: SLUG__SUBMIT_SIGNATURE,
    [PATH_COMPONENT__SUBMIT_CONFIRMATION]: SLUG__SUBMIT_CONFIRMATION,
    [PATH_COMPONENT__SUBMIT_EXIT_BOUNCE]: SLUG__SUBMIT_EXIT_BOUNCE,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO]: SLUG__SUBMIT_CONFIRM_ID_INTRO,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID]: SLUG__SUBMIT_CONFIRM_ID
  };
};

export const getQuestionQueries = ({ question }) => {
  if (question.slug === SLUG__SUBMIT_CONFIRMATION) {
    return [
      {
        collectionType: COLLECTION_TYPE__SELF,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SELF,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_LAST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SPOUSE,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SPOUSE,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_LAST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__HOME_ADDRESS,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: SLUG__HOME_ADDRESS_STREET
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_STANDARD,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION
      },
      {
        collectionType: COLLECTION_TYPE__HOME,
        slug: HOME_ENDPOINT_ATTRIBUTES.OPTIONS
      },
      {
        collectionType: COLLECTION_TYPE__CAR,
        slug: CAR_ENDPOINT_ATTRIBUTES.OPTIONS
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_EARNED_INCOME,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_EARNED_INCOME_STARTED
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_QUARTERLY_TAX,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_QUARTERLY_TAX_DONE
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_HSA,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HSA_CONTRIBUTION
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_CAPITAL_LOSS,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CAPITAL_LOSS_DONE
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_IRA,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_IRA_CONTRIBUTION
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_STUDENT_LOANS,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STUDENT_LOANS_LENDER
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_STUDENT_TUITION,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STUDENT_TUITION_SCHOOL
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_CHILD_CARE,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHILD_CARE_PROVIDER_NAME
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_CHARITY,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_COGS,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_COGS
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_COGS,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_BUSINESS_LOANS_INTEREST
      },
      {
        collectionType: COLLECTION_TYPE__CREDIT_HOMEOWNER,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_STARTED
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2_EMPLOYER
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_1099G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_1099G_PAYER_NAME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_1099G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_INTEREST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_INTEREST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INTEREST_PAYER_NAME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_INTEREST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INTEREST_GOVERNMENT_INTEREST
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_DIV,
        slug: ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_DIV,
        slug: ENDPOINT_ATTRIBUTE__INCOME_DIV_PAYER_NAME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_INVEST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_INVEST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_DESCRIPTION
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2G_PAYER_NAME
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
        slug: ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_DESCRIPTION
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
        slug: ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT
      }
    ];
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE) {
    return [
      {
        collectionType: COLLECTION_TYPE__SUBMIT_SIGNATURE
      },
      {
        collectionType: COLLECTION_TYPE__SELF,
        slug: ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SELF,
        slug: ENDPOINT_ATTRIBUTE__SELF_LAST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SPOUSE,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
      },
      {
        collectionType: COLLECTION_TYPE__SPOUSE,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME
      }
    ];
  }

  return [];
};
