import React, { useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { updateTaxData, useGetCarPillsQuery } from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getErrors } from '@app/src/services/taxValidationService';
import store from '@app/src/store/store';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { advanceToNextQuestionnaireQuestion } from '@app/src/taxflow/main/services/mainService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { getSlugMap } from '@app/src/taxflow/sections/car/carUtils';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowCarPills = ({
  refreshValidation,
  isQuestionnaireFlow,
  advanceToNextQuestionnaireQuestion,
  updateTaxData
}) => {
  const { data: carPills, isLoading: carPillsLoading } = useGetCarPillsQuery();

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [collectionDeleteModalTarget, setCollectionDeleteModalTarget] = useState({
    collectionType: undefined,
    collectionId: undefined
  });

  if (carPillsLoading) {
    return null;
  }

  const confirmPillDeletion = ({ collectionType, collectionId, text }) => {
    trackActivity('car: pill removal requested', { collectionType, collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setCollectionDeleteModalTarget({
      collectionType: collectionType,
      collectionId
    });
  };

  const onClickCarPill = async ({ startSlug, collectionType, collectionId, text }) => {
    trackActivity('car: pill clicked', { collectionType, collectionId, pillName: text });
    const nextPathComponent = getPathComponentBySlug(startSlug);
    if (_.isNil(nextPathComponent)) {
      defaultCaptureMessage('Could not advance to next car question', {
        coll_type: collectionType,
        coll_id: collectionId,
        slug: startSlug
      });
      return;
    }
    updateTaxData({
      taxData: [
        {
          coll_id: DEFAULT_COLLECTION_ID,
          coll_type: COLLECTION_TYPE__CAR,
          slug: CAR_SLUGS.NAV_START,
          value: '1'
        }
      ],
      generateSharedCollectionId: false
    });

    if (isQuestionnaireFlow) {
      const slugMap = getSlugMap();
      await store.dispatch(
        advanceToNextQuestionnaireQuestion({
          nextSlug: slugMap[nextPathComponent],
          nextCollectionId: collectionId,
          questionnaireEarlyExit: false,
          history
        })
      );
    } else {
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    }
  };

  const onPillRemoved = () => {
    refreshValidation();
  };

  const deriveVariant = (pill) => {
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  return carPills.map((pill, index) => {
    const { collectionType, collectionId, text } = pill;
    return (
      <div key={index}>
        <TaxFlowPill
          text={pill.text}
          removable={pill.removable}
          variant={deriveVariant(pill)}
          onClick={() => onClickCarPill(pill)}
          onRemove={() => confirmPillDeletion({ collectionType, collectionId, text })}
        />
        {showCollectionDeleteModal && (
          <TaxFlowCollectionDeleteModal
            collectionType={collectionDeleteModalTarget.collectionType}
            collectionId={collectionDeleteModalTarget.collectionId}
            hideModal={() => setShowCollectionDeleteModal(false)}
            onDelete={onPillRemoved}
          />
        )}
      </div>
    );
  });
};

const mapStateToProps = (state) => {
  return {
    isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
  };
};

const mapDispatchToProps = {
  refreshValidation: getErrors,
  advanceToNextQuestionnaireQuestion,
  updateTaxData
};

const ConnectedTaxFlowCarPills = connect(mapStateToProps, mapDispatchToProps)(TaxFlowCarPills);

export default ConnectedTaxFlowCarPills;
