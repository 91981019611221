import React from 'react';
import _ from 'lodash';
import BankInfo from '@app/src/Components/BankInfo/BankInfo';
import BankRelink from '@app/src/Components/BankRelink/BankRelink';
import CapitalOnePrompt from '@app/src/Components/CapitalOnePrompt/CapitalOnePrompt';
import CapitalOneRedirect from '@app/src/Components/CapitalOneRedirect/CapitalOneRedirect';
import Oauth from '@app/src/Components/Common/Oauth/Oauth';
import ContractorLoading from '@app/src/Components/ContractorLoading/ContractorLoading';
import ContractorLogout from '@app/src/Components/ContractorLogout/ContractorLogout';
import ExpenseReviewContent from '@app/src/Components/ExpenseReview/ExpenseReviewContent';
import HolisticOnboardingApp from '@app/src/Components/HolisticOnboarding/HolisticOnboardingApp';
import ImpersonationLoading from '@app/src/Components/ImpersonationLoading/ImpersonationLoading';
import LoginEmail from '@app/src/Components/Login/LoginWithOTP/LoginEmail';
import LoginPhone from '@app/src/Components/Login/LoginWithOTP/LoginPhone';
import LoginVerify from '@app/src/Components/Login/LoginWithOTP/LoginVerify';
import OnboardingApp from '@app/src/Components/Onboarding/pages/OnboardingApp';
import Referrals from '@app/src/Components/Referrals/Referrals';
import SettingsContainer from '@app/src/Components/Settings/SettingsContainer';
import InternalTaxCalculator from '@app/src/Components/TaxCalculator/InternalTaxCalculator';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import TaxFlowContainer from '@app/src/taxflow/main/components/TaxFlowContainer';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = _.compact([
  {
    path: '/h',
    exact: false,
    component: HolisticOnboardingApp,
    private: false
  },
  {
    path: '/onboarding',
    exact: false,
    component: OnboardingApp,
    private: false
  },
  {
    path: `/${TAXFLOW_BASE_URL}/oauth`,
    exact: true,
    component: (props) => (
      <Oauth
        {...props}
        loadOnSuccess
        successRoute='/tax-filing/submit-debit'
        defaultRoute='/tax-filing/submit-debit-manual'
        getSuccessParams={({ metadata }) => {
          const account_id = _.get(metadata, ['accounts', 0, 'id']);

          return new URLSearchParams({ account_id: account_id, plaid: true });
        }}
      />
    ),
    private: true
  },
  {
    path: `/${TAXFLOW_BASE_URL}`,
    exact: false,
    component: TaxFlowContainer,
    private: false
  },
  {
    path: '/login',
    exact: true,
    component: LoginPhone,
    publicOnly: true
  },
  {
    path: '/login-otp',
    exact: true,
    component: LoginPhone,
    publicOnly: true
  },
  {
    path: '/login-email',
    exact: true,
    component: LoginEmail,
    publicOnly: true
  },
  {
    path: '/login-verify',
    exact: true,
    component: LoginVerify,
    publicOnly: true
  },
  {
    path: '/bank-details/relink',
    exact: true,
    component: BankRelink,
    private: true
  },
  {
    path: '/bank-details/:id',
    exact: true,
    component: BankInfo,
    private: true
  },
  {
    path: '/settings',
    component: SettingsContainer,
    private: true
  },
  {
    path: '/linked-accounts',
    exact: true,
    component: TaxFlowContainer,
    private: true
  },
  {
    path: '/capital-one-prompt',
    exact: true,
    component: CapitalOnePrompt,
    private: true
  },
  {
    path: '/capital-one-redirect',
    exact: true,
    component: CapitalOneRedirect,
    private: true
  },
  {
    path: '/contractor-loading',
    exact: true,
    component: ContractorLoading,
    private: false
  },
  {
    path: '/contractor-logout',
    exact: true,
    component: ContractorLogout,
    private: true
  },
  {
    path: '/impersonation-loading',
    exact: true,
    component: ImpersonationLoading,
    private: false
  },
  {
    path: '/expenses',
    exact: true,
    component: ExpenseReviewContent,
    private: true
  },
  {
    path: '/referrals',
    exact: true,
    component: Referrals,
    private: true
  },
  REACT_APP_ENV !== 'production' && {
    path: '/internal/tax-calc',
    exact: true,
    component: InternalTaxCalculator,
    private: true
  }
]);

export default routes;
