import { combineReducers } from 'redux';
import plaidApi from '@app/src/api/plaidApi';
import profileApi from '@app/src/api/profileApi';
import taxDataApi from '@app/src/api/taxDataApi';
import taxFlowAssistantReducer from '@app/src/store/reducers/assistantReducer';
import authReducer from '@app/src/store/reducers/authReducer';
import bankReducer from '@app/src/store/reducers/bankReducer';
import dashboard2Reducer from '@app/src/store/reducers/dashboardReducer';
import expenseReviewReducer from '@app/src/store/reducers/expenseReviewReducer';
import holisticOnboardingReducer from '@app/src/store/reducers/holisticOnboardingReducer';
import mobileRedirectReducer from '@app/src/store/reducers/mobileRedirectReducer';
import onboardingReducer from '@app/src/store/reducers/onboardingReducer';
import pricing2Reducer from '@app/src/store/reducers/pricingReducer';
import referralsReducer from '@app/src/store/reducers/referralsReducer';
import settingsReductor from '@app/src/store/reducers/settingsReducer';
import taxFlowModalsReducer from '@app/src/store/reducers/taxFlowModalsReducer';
import taxFlowNavigationReducer from '@app/src/store/reducers/taxFlowNavigationReducer';
import taxFlowReducer from '@app/src/store/reducers/taxFlowReducer';
import taxValidationReducer from '@app/src/store/reducers/taxValidationReducer';
import workReducer from '@app/src/store/reducers/workReducer';

export default combineReducers({
  auth: authReducer,
  work: workReducer,
  expenseReview: expenseReviewReducer,
  bank: bankReducer,
  onboarding: onboardingReducer,
  holisticOnboarding: holisticOnboardingReducer,
  taxFlow: taxFlowReducer,
  taxFlowModals: taxFlowModalsReducer,
  assistant: taxFlowAssistantReducer,
  taxValidation: taxValidationReducer,
  taxFlowNavigation: taxFlowNavigationReducer,
  dashboard2: dashboard2Reducer,
  pricing2: pricing2Reducer,
  settings: settingsReductor,
  mobileRedirect: mobileRedirectReducer,
  referrals: referralsReducer,
  [taxDataApi.reducerPath]: taxDataApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [plaidApi.reducerPath]: plaidApi.reducer
});
