import React, { forwardRef } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = forwardRef(
  (
    {
      thousandSeparator,
      decimalScale,
      inputMode,
      allowNegative,
      maxLength,
      isAllowed,
      onChange,
      allowLeadingZeros,
      ...props
    },
    ref
  ) => (
    <NumberFormat
      {..._.omit(props, ['dispatch'])}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          value: values.value
        });
      }}
      decimalSeparator='.'
      thousandSeparator={_.defaultTo(thousandSeparator, ',')}
      decimalScale={_.defaultTo(decimalScale, 2)}
      inputMode={_.defaultTo(inputMode, 'decimal')}
      allowNegative={_.defaultTo(allowNegative, false)}
      maxLength={maxLength}
      isAllowed={
        isAllowed
          ? isAllowed
          : () => {
              return true;
            }
      }
      allowLeadingZeros={allowLeadingZeros}
      fixedDecimalScale={_.defaultTo(decimalScale, 2) > 0}
    />
  )
);

export default NumberFormatCustom;
