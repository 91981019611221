import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowSliderItem from '@app/src/Components/TaxFlow/Question/TaxFlowSliderItem';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import { replaceStrings } from '@app/src/taxflow/common';

const TaxFlowFormSliderItem = ({ question, answer, onChange, onFocus, replaceStrings }) => {
  useEffect(() => {
    if (_.isNil(_.get(answer, 'value'))) {
      if (question && question.question_meta && question.question_meta.default) {
        onChange({
          slug: question.slug,
          value: question.question_meta.default
        });
      }
    }
  }, [question, answer, onChange]);

  const handleChange = (value) => {
    onChange({
      slug: question.slug,
      value,
      endpoint_attr: question.endpoint_attr
    });
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus({ endpoint_attr: question.endpoint_attr });
    }
  };

  return (
    <div className='form-group'>
      <TaxValidationLabel question={question} answer={answer} title={replaceStrings(question.title)} />
      <TaxFlowSliderItem
        question={question}
        answer={answer}
        onChange={(e) => handleChange(e.value)}
        onFocus={handleFocus}
        isChildQuestion
      />
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  replaceStrings
};

const ConnectedTaxFlowFormSliderItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormSliderItem);

export default ConnectedTaxFlowFormSliderItem;
