import React from 'react';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import BasePill from '@app/src/Components/Common/BasePill/BasePill';
import ExpenseReviewCategoryCountPill from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCountPill';
import { orderedCategoryAmountsSelector } from '@app/src/selectors/expenseReviewSelectors';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCounts.scss';

const ExpenseReviewCategoryCounts = ({ categories, loading }) => {
  const firstTenCategories = categories.slice(0, 10);
  const restCount = categories.slice(10).length;

  return (
    <Stack direction='row' spacing={0.5}>
      {loading ? (
        <>
          {Array.from({ length: 10 }).map((val, index) => (
            <ExpenseReviewCategoryCountPill loading key={index} />
          ))}
        </>
      ) : (
        <>
          {firstTenCategories.map(({ categoryId, count }, index) => (
            <ExpenseReviewCategoryCountPill id={categoryId} count={count} key={index} />
          ))}
          {restCount > 0 && (
            <BasePill>
              <div className='category-tile-text'>{restCount} More</div>
            </BasePill>
          )}
        </>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  categories: orderedCategoryAmountsSelector(state)
});

const ConnectedExpenseReviewCategoryCounts = connect(mapStateToProps, null)(ExpenseReviewCategoryCounts);

export default ConnectedExpenseReviewCategoryCounts;
