import _ from 'lodash';
import { useGetCampaignQuery, useGetFeaturesQuery } from '@app/src/api/profileApi';
import {
  useGetBulkUploadPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGetReviewPillsQuery,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useGetTaxDataQuery
} from '@app/src/api/taxDataApi';
import { useQuestionnaireLoading } from '@app/src/taxflow/main/hooks/useQuestionnaireLoading';
import { useUnlockedViaImpersonation } from '@app/src/taxflow/main/hooks/useUnlockedViaImpersonation';
import {
  SECTION__KEEPER_REVIEW,
  SECTION__SHARE_DETAILS,
  SECTION__SIGN_AND_FILE,
  UI_STAGE__BLOCKED_PREFIX,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE__EMAIL_OUTBOX_PREFIX,
  UI_STAGE__NOT_STARTED,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

export const useUIState = () => {
  const { data: specialTaxData, isLoading: specialTaxDataLoading } = useGetTaxDataQuery({
    collectionType: 'special'
  });
  const { data: bulkUploadPills, isLoading: bulkUploadPillsLoading } = useGetBulkUploadPillsQuery();
  const { data: questionnaireProgress, isLoading: questionnaireProgressLoading } = useGetQuestionnaireProgressQuery();
  const { data: submitWarnings, isLoading: submitWarningsLoading } = useGetSubmitWarningsQuery();
  const { data: submitIssues, isLoading: submitIssuesLoading } = useGetSubmitIssuesQuery();
  const { data: reviewPills, isLoading: reviewPillsLoading } = useGetReviewPillsQuery();
  const { data: campaign, isLoading: uiStageLoading } = useGetCampaignQuery();
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery();
  const { questionnaireLoading } = useQuestionnaireLoading();

  const isUserUnlockedViaImpersonation = useUnlockedViaImpersonation();

  const isLoading =
    specialTaxDataLoading ||
    bulkUploadPillsLoading ||
    questionnaireProgressLoading ||
    reviewPillsLoading ||
    submitWarningsLoading ||
    submitIssuesLoading ||
    uiStageLoading ||
    featuresLoading;

  return {
    uiState: getUIState({
      specialTaxData,
      bulkUploadPills,
      questionnaireComplete: questionnaireProgress?.questionnaireComplete,
      uiStage: campaign?.ui_stage,
      submitWarnings,
      submitIssues,
      reviewPills,
      questionnaireLoading,
      features,
      isUserUnlockedViaImpersonation,
      isLoading
    }),
    isLoading
  };
};

/**
 * TODO - we could move out the bulk of this logic to contentful
 */
const getUIState = ({
  specialTaxData,
  bulkUploadPills,
  questionnaireComplete,
  uiStage,
  submitWarnings,
  submitIssues,
  reviewPills,
  questionnaireLoading,
  features,
  isUserUnlockedViaImpersonation,
  isLoading
}) => {
  const userClaimsAllFormsUploaded = _.some(specialTaxData, {
    coll_type: 'special',
    slug: 'all-forms-uploaded',
    value: '1'
  });
  const effectiveUIStage =
    _.isNil(uiStage) || _.isEmpty(uiStage) || isUserUnlockedViaImpersonation
      ? UI_STAGE__NOT_STARTED
      : _.last(_.split(uiStage, ' - '));
  const uiStageMetadata = _.find(UI_STAGE_METADATA, { stage: effectiveUIStage });

  if (_.isNil(uiStageMetadata)) {
    defaultCaptureMessage('[unexpected_edge_case] could not get metadata for ui stage', { uiStage: effectiveUIStage });
  }
  const emailOutbox = _.startsWith(uiStage, UI_STAGE__EMAIL_OUTBOX_PREFIX);
  const blocked = _.startsWith(uiStage, UI_STAGE__BLOCKED_PREFIX);
  const skipQuestionnaire = _.some(features, { name: 'skip-questionnaire', value: 1 });

  const HOME_SECTION___BULK_UPLOAD = {
    slug: 'bulk-upload',
    title: _.isEmpty(bulkUploadPills) ? 'Drop off tax forms' : `Your tax forms (${_.size(bulkUploadPills)})`,
    variant: userClaimsAllFormsUploaded ? 'collapsible' : 'default',
    collapsed: userClaimsAllFormsUploaded && !_.some(bulkUploadPills, { loading: true }),
    hidden: !uiStageMetadata?.preSubmit
  };
  const HOME_SECTION___QUESTIONNAIRE = {
    slug: 'questionnaire',
    title: 'Answer personalized questionnaire',
    variant:
      !userClaimsAllFormsUploaded || skipQuestionnaire ? 'disabled' : questionnaireLoading ? 'loading' : 'default',
    disabledText: 'Finish tax forms to unlock',
    hidden: questionnaireComplete || !uiStageMetadata?.preSubmit || skipQuestionnaire
  };
  const HOME_SECTION___REVIEW = {
    slug: 'review',
    title: questionnaireComplete ? 'Your tax information' : 'Start Keeper review',
    variant:
      (skipQuestionnaire && userClaimsAllFormsUploaded) || questionnaireComplete || !uiStageMetadata?.preSubmit
        ? 'default'
        : 'disabled',
    disabledText: 'Complete questionnaire',
    hidden: uiStageMetadata?.showReviewPills === false || emailOutbox
  };

  const homeSections = [HOME_SECTION___BULK_UPLOAD, HOME_SECTION___QUESTIONNAIRE, HOME_SECTION___REVIEW];

  if (isLoading) {
    return {
      sections: [
        {
          slug: 'bulk-upload',
          hidden: true
        },
        {
          slug: 'questionnaire',
          hidden: true
        },
        {
          slug: 'review',
          hidden: true
        }
      ],
      showSubmitButton: false,
      submitButtonEnabled: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showSubmitWarnings: false
    };
  }

  if (emailOutbox) {
    return {
      slug: 'email-outbox',
      title: 'Please check your email',
      maybeSubTitle: `We need additional clarification about your tax return. To help us file your taxes, please be on the lookout for an email from support@keepertax.com!`,
      sections: uiStageMetadata.sections,
      showSubmitButton: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showEmailPill: true,
      showSubmitWarnings: false,
      homeSections
    };
  }

  if (blocked) {
    return {
      slug: 'blocked',
      title: 'Your tax return is almost ready to be submitted',
      maybeSubTitle: `Some parts of your tax return are not available for filing yet. Once the IRS and your state are ready to accept your tax return, our professional tax reviewer will send you an email to let you know your return is ready to be filed.`,
      sections: uiStageMetadata.sections,
      showSubmitButton: false,
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitWarnings: true,
      homeSections
    };
  }

  if (uiStage === UI_STAGE__CONFIRM_AMOUNTS && !_.isEmpty(submitIssues)) {
    return {
      slug: 'confirm-amounts-submit-issue',
      title: 'We found some issues with your bank info',
      maybeSubTitle: uiStageMetadata.subTitle,
      sections: uiStageMetadata.sections,
      showSubmitButton: uiStageMetadata.showSubmitButton,
      submitButtonEnabled: uiStageMetadata.submitButtonEnabled,
      submitButtonText: 'Update bank info',
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitWarnings: true,
      homeSections
    };
  }

  const hasTaxDataError = _.some(reviewPills, ({ pills }) => _.some(pills, { warning: true }));
  const filingEnabled = _.isEmpty(submitWarnings) && _.isEmpty(submitIssues) && !hasTaxDataError;
  if (effectiveUIStage !== UI_STAGE__NOT_STARTED) {
    return {
      slug: effectiveUIStage,
      title: uiStageMetadata.title,
      maybeSubTitle: uiStageMetadata.subTitle,
      sections: uiStageMetadata.sections,
      showSubmitButton: uiStageMetadata.showSubmitButton,
      submitButtonEnabled: uiStageMetadata.submitButtonEnabled && (!uiStageMetadata?.preSubmit || filingEnabled),
      submitButtonText: uiStageMetadata.submitButtonText,
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitToIRSElement: uiStageMetadata.showSubmitToIRSElement,
      showFiledWithIRSElement: uiStageMetadata.showFiledWithIRSElement,
      showAcceptedByIRSElement: uiStageMetadata.showAcceptedByIRSElement,
      showSubmitWarnings: uiStageMetadata.showSubmitWarnings,
      homeSections
    };
  }
  const submitButtonText = 'Continue to Keeper review';

  if (questionnaireComplete || (userClaimsAllFormsUploaded && skipQuestionnaire)) {
    return {
      slug: 'review',
      title: 'Anything else?',
      maybeSubTitle: 'You’ll have another chance to look over your return before e-filing with {irsAndState}',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 100 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: filingEnabled,
      submitButtonText,
      showSubmitWarnings: true
    };
  }

  if (userClaimsAllFormsUploaded) {
    return {
      slug: 'questionnaire',
      title: 'Answer personalized questions',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 75 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: false,
      submitButtonText,
      showSubmitWarnings: false
    };
  }

  if (!_.isEmpty(bulkUploadPills)) {
    return {
      slug: 'bulk-started',
      title: 'Add all your forms before you continue',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 50 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: false,
      submitButtonText,
      showSubmitWarnings: false
    };
  }

  return {
    slug: 'bulk',
    title: 'Let’s drop off your tax forms',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 25 },
      { ...SECTION__KEEPER_REVIEW, progress: 0 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    homeSections,
    showSubmitButton: true,
    submitButtonEnabled: false,
    submitButtonText,
    showSubmitWarnings: false
  };
};
