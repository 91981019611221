import React from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useWindowSize } from 'react-use';
import AnimatedNumber from '@app/src/Components/Common/AnimatedNumber/AnimatedNumber';
import ExpenseReviewCategoryCountPillContainer from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCounts';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import { numberWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  isInitialExpensesLoadSelector,
  savingsAmountSelector,
  sidebarComponentSelector,
  totalWriteOffsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesSavingsContainer.scss';

const ExpenseReviewExpensesSavingsContainer = ({
  isInitialExpensesLoad,
  totalWriteOffs,
  savingsAmount,
  empty,
  isSidebarOpen,
  setSidebarComponent
}) => {
  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth >= 1024;

  const handleSavingsClick = () => {
    if (isSidebarOpen) {
      setSidebarComponent(null);
      return;
    }

    trackActivity('navigation: tax savings info', { origin: 'web' });
    setSidebarComponent('SavingsInfo');
  };

  return (
    <div className='expense-review-expenses-savings-container' style={{ marginBottom: '16px' }}>
      <Button className='header-savings' variant='outlined' onClick={handleSavingsClick}>
        <div>
          <div className='expense-review-expenses-savings-text'>Estimated Tax Savings</div>
          <div
            className={classNames('expense-review-expenses-savings-amount', {
              'expense-review-expenses-savings-amount-loading': isInitialExpensesLoad || empty
            })}
          >
            {isInitialExpensesLoad || empty ? (
              '$???'
            ) : (
              <div className='flex align-center'>
                $<AnimatedNumber value={numberWith0DecimalPlaces(savingsAmount)} />
              </div>
            )}
          </div>
          <div className={'expense-review-expenses-savings-text'}>
            {isInitialExpensesLoad && !empty ? 'Loading' : `${totalWriteOffs} deductions`}
          </div>
          {isDesktop && <ExpenseReviewCategoryCountPillContainer loading={isInitialExpensesLoad} />}
        </div>
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  savingsAmount: savingsAmountSelector(state),
  totalWriteOffs: totalWriteOffsSelector(state),
  isSidebarOpen: sidebarComponentSelector(state) === 'SavingsInfo'
});

const mapDispatchToProps = {
  setSidebarComponent
};

const ConnectedExpenseReviewExpensesSavingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReviewExpensesSavingsContainer);

export default ConnectedExpenseReviewExpensesSavingsContainer;
