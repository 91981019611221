import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import { homeOfficeAmountsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowHomeOfficeGraph.scss';

const TaxFlowHomeOfficeGraph = ({ homeOfficeAmounts }) => {
  const regularAmount = homeOfficeAmounts.regularAmount;
  const simplifiedAmount = homeOfficeAmounts.simplifiedAmountCapped;

  const maxDeduction = Math.max(regularAmount, simplifiedAmount);

  const [regularLabelAbove, setRegularLabelAbove] = useState(false);
  const [simplifiedLabelAbove, setSimplifiedLabelAbove] = useState(false);

  const regularRef = useRef(null);
  const regularLabelRef = useRef(null);

  const simplifiedRef = useRef(null);
  const simplifiedLabelRef = useRef(null);

  useEffect(() => {
    if (regularRef.current && regularLabelRef.current) {
      setRegularLabelAbove(regularRef.current.clientHeight < regularLabelRef.current.clientHeight);
    }
    if (simplifiedRef.current && simplifiedLabelRef.current) {
      setSimplifiedLabelAbove(simplifiedRef.current.clientHeight < simplifiedLabelRef.current.clientHeight);
    }
  }, [regularRef, regularLabelRef, simplifiedRef, simplifiedLabelRef]);

  const regularBarStyle = useMemo(
    () => ({
      height: `${(regularAmount * 100) / maxDeduction}%`,
      ...(regularAmount === 0 && { border: 'none' })
    }),
    [regularAmount, maxDeduction]
  );

  const simplifiedBarStyle = useMemo(
    () => ({
      height: `${(simplifiedAmount * 100) / maxDeduction}%`,
      ...(simplifiedAmount === 0 && { border: 'none' })
    }),
    [simplifiedAmount, maxDeduction]
  );

  return (
    <div className='taxflow-home-office-graph'>
      <div className='bar regular' style={regularBarStyle} ref={regularRef}>
        <div
          className={classNames('label', {
            above: regularLabelAbove
          })}
          ref={regularLabelRef}
        >
          <div>Regular method</div>
          <strong>{currencyWith0DecimalPlaces(regularAmount)}</strong>
        </div>
      </div>
      <div className='bar simplified' style={simplifiedBarStyle} ref={simplifiedRef}>
        <div
          className={classNames('label', {
            above: simplifiedLabelAbove
          })}
          ref={simplifiedLabelRef}
        >
          <div>Simplified method</div>
          <strong>{currencyWith0DecimalPlaces(simplifiedAmount)}</strong>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  homeOfficeAmounts: homeOfficeAmountsSelector(state)
});

const ConnectedTaxFlowHomeOfficeGraph = connect(mapStateToProps)(TaxFlowHomeOfficeGraph);

export default ConnectedTaxFlowHomeOfficeGraph;
