import React, { Component } from 'react';
import { connect } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import { setBankLoader, setBankListUpdateStatus, setBankLinkFailure } from '@app/src/actions/bankActions';
import { setOnboardingError } from '@app/src/actions/onboardingActions';
import { userSelector } from '@app/src/selectors/userSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getBankSummaryV2 } from '@app/src/services/bankService';
import { runRetroJob } from '@app/src/services/onboardingService';

class OnboardingConnectBank extends Component {
  componentDidMount() {
    if (!this.props.user || !this.props.user.phone) {
      this.props.history.push('/');
    }

    const bankLinkFailure = JSON.parse(localStorage.getItem('bankLinkFailure'));

    if (bankLinkFailure) {
      this.props.setBankLinkFailure(bankLinkFailure);
    }

    window.addEventListener('beforeunload', this.handleWindowClose);
  }

  handleWindowClose = async () => {
    trackActivity('run retro: frontend triggered by event listener');
    await this.props.runRetroJob({ force: true });
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowClose);
  }

  setErrorMsg = (msg) => {
    if (this._timeout) clearTimeout(this._timeout);

    this.props.setOnboardingError(msg);

    this._timeout = setTimeout(() => {
      this.props.setOnboardingError('');
    }, 3000);
  };

  onBankLinked = () => {
    //set bank list updated
    this.props.setBankListUpdateStatus(true);
  };

  render() {
    const { bank } = this.props;
    const { bankList } = bank;

    return (
      <>
        <div className='steps-body bank-list-body'>
          <div className={'bank-list-wrapper ' + (bankList.length === 0 ? ' bank-nolist' : '')}>
            <BankList isOnboarding={true} onError={this.setErrorMsg} onBankLinked={this.onBankLinked} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bank: state.bank,
  user: userSelector(state)
});

const mapDispatchToProps = {
  getBankSummaryV2,
  setOnboardingError,
  setBankLoader,
  runRetroJob,
  setBankLinkFailure,
  setBankListUpdateStatus
};

const ConnectedOnboardingConnectBank = connect(mapStateToProps, mapDispatchToProps)(OnboardingConnectBank);

export default ConnectedOnboardingConnectBank;
