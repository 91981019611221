import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import NumberFormat from 'react-number-format';
import '@app/src/Components/Common/KeeperSlider/KeeperSlider.scss';

const KeeperSlider = ({
  value,
  increment = 1,
  max = 100,
  min = 0,
  label,
  onSliderChange,
  onInputChange = () => {},
  format = '###%',
  showInput,
  ...props
}) => (
  <div>
    {label && <InputLabel>{label}</InputLabel>}
    <div className='slider-input'>
      {showInput && (
        <NumberFormat
          format={format}
          onValueChange={({ value }) => onInputChange(value)}
          isAllowed={({ floatValue }) => floatValue >= min && floatValue <= max}
          value={value}
          customInput={(inputProps) => (
            <OutlinedInput
              className='slider-input-base'
              inputProps={{
                ...inputProps,
                style: {
                  textAlign: 'center'
                }
              }}
            />
          )}
          {...props}
        />
      )}
      <Slider
        variant='keeper-slider'
        step={increment}
        onChange={(event, value) => onSliderChange(value)}
        value={Number(value)}
        min={min}
        max={max}
        {...props}
      />
    </div>
  </div>
);

export default KeeperSlider;
