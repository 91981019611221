import _ from 'lodash';

export const infoModalShowSelector = (state) => _.get(state, ['taxFlowModals', 'infoModalShow']);
export const infoModalItemSelector = (state) => _.get(state, ['taxFlowModals', 'infoModalItem']);
export const collectionModalTitleSelector = (state) => _.get(state, ['taxFlowModals', 'infoModalTitle']);
export const collectionModalDescriptionSelector = (state) => _.get(state, ['taxFlowModals', 'infoModalDescription']);
export const collectionInfoModalShowSelector = (state) => _.get(state, ['taxFlowModals', 'collectionInfoModalShow']);
export const isConfirmationModalOpenSelector = (state) => _.get(state, ['taxFlowModals', 'isConfirmationModalOpen']);
export const isPaymentModalOpenSelector = (state) => _.get(state, ['taxFlowModals', 'isPaymentModalOpen']);
export const collectionFormUploadErrorModalShowSelector = (state) =>
  _.get(state, ['taxFlowModals', 'collectionFormUploadErrorModalShow']);
export const premiumUpsellModalShowSelector = (state) => _.get(state, ['taxFlowModals', 'premiumUpsellModalShow']);
