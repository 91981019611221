import _ from 'lodash';
import axios from 'axios';
import { setSubscriptionPromptShow, setTaxFlowError } from '@app/src/actions/taxFlowActions';
import { getPaid } from '@app/src/api/plaidApi';
import profileApi, { TAG__CAMPAIGN } from '@app/src/api/profileApi';
import { updateTaxData } from '@app/src/api/taxDataApi';
import { PAYMENT_ERROR_MESSAGE } from '@app/src/constants/pricingConstants';
import { serverUrl } from '@app/src/global/Environment';
import { sentMsgToReactNative } from '@app/src/global/Helpers';
import { annualPriceSelector, premiumPriceSelector } from '@app/src/selectors/userSelectors';
import { freeTaxFilingSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { createSubscription, generateStripeCustomer } from '@app/src/services/pricingService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { isPremiumSubscriberSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
  ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT,
  SLUG__SUBMIT_SUBSCRIPTION_PROMPT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { setIsPaymentValid, setQueryResults } from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const clearPaymentType = () => (dispatch) => {
  dispatch(setCurrentAnswer({ value: { paymentType: null } }));
};

export const subscribeWithToken =
  ({ token }) =>
  async (dispatch, getState) => {
    const annualPrice = annualPriceSelector(getState());
    const premiumPrice = premiumPriceSelector(getState());
    const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
    const price = isPremiumSubscriber ? premiumPrice : annualPrice;

    if (!_.get(token, 'id')) {
      notify('Please enter your credit card details');
      trackActivity('tax file: add payment method fail', { price });
      return;
    }
    await dispatch(
      generateStripeCustomer({
        stripeToken: token.id,
        skipEvent: true,
        product: 'bookkeeping',
        callback: async (e) => {
          dispatch(setTaxFlowError(e));
          notify(e.message || PAYMENT_ERROR_MESSAGE);
          defaultCaptureException(e);
        },
        successCallback: async () => {
          dispatch(setTaxFlowError(null));
          const account_id = _.get(getState(), ['bank', 'chargeableAccount', 'account_id']);
          const planType = isPremiumSubscriber ? 'premium' : 'annual';

          const res = await dispatch(createSubscription({ account_id, origin: 'tax filing', planType }));

          if (_.get(res, ['data', 'status']) === 'error') {
            const errorMsg = _.get(res, ['data', 'data', 'error']);
            trackActivity('Purchase Failed', {
              product: 'bookkeeping',
              currency: 'USD',
              price: annualPrice,
              error: errorMsg
            });

            dispatch(setTaxFlowError(errorMsg));
            notify(errorMsg || PAYMENT_ERROR_MESSAGE);
          }
        }
      })
    );
  };

export const updateReadyToFile = (irsEnabled) => async (dispatch) => {
  await axios.post(`${baseUrl}api/taxes/update-ready-to-file`, { irsEnabled });
  await dispatch(profileApi.util.invalidateTags([TAG__CAMPAIGN]));
};

const dismissSubscriptionPrompt = () => async (dispatch, getState) => {
  dispatch(setSubscriptionPromptShow(false));

  const queryResults = queryResultsSelector(getState());
  const newQueryResults = queryResults.map((queryResult) =>
    queryResult.coll_type === COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT &&
    queryResult.coll_id === DEFAULT_COLLECTION_ID &&
    queryResult.slug === SLUG__SUBMIT_SUBSCRIPTION_PROMPT
      ? {
          ...queryResult,
          answer: {
            ...queryResult.answer,
            value: '1'
          }
        }
      : queryResult
  );
  dispatch(setQueryResults(newQueryResults));
  await dispatch(
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT,
          value: '1'
        }
      ]
    })
  );
};

const openSubscribeModal = () => async () => {
  sentMsgToReactNative('open_subscribe_modal', { origin: 'subscription prompt' });
};

export const startSubscriptionNow = () => async (dispatch) => {
  trackActivity('subscription prompt: start now');
  await Promise.all([dispatch(dismissSubscriptionPrompt()), dispatch(openSubscribeModal())]);
};

export const startSubscriptionLater = () => async (dispatch) => {
  trackActivity('subscription prompt: start later');
  await dispatch(dismissSubscriptionPrompt());
};

export const updateIsPaymentValid = (isPaymentValid) => (dispatch) => {
  dispatch(setIsPaymentValid(isPaymentValid));
};

export const getTaxFilingPaid = () => async (dispatch, getState) => {
  const isFree = freeTaxFilingSelector(getState());
  const paid = await dispatch(getPaid());
  return paid || isFree;
};
