import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import plaidApi from '@app/src/api/plaidApi';
import profileApi from '@app/src/api/profileApi';
import taxDataApi from '@app/src/api/taxDataApi';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import rootReducer from '@app/src/store/reducers';
import { streamMiddleware } from '@app/src/utils/messagingMiddleware';
import { createSocketMiddleware } from '@app/src/utils/socket';

const initialState = {};
const middleware = [
  thunk,
  createSocketMiddleware(),
  streamMiddleware,
  taxDataApi.middleware,
  profileApi.middleware,
  plaidApi.middleware
];

// eslint-disable-next-line import/no-unused-modules
export const initializeStore = ({ preloadedState = initialState, enhancers = [] } = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false
      }),
      ...middleware,
      ...enhancers
    ],
    devTools: { trace: REACT_APP_ENV === 'development' }
  });
};

const store = initializeStore({
  preloadedState: initialState
});

export default store;
