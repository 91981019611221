import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowFormPercentItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormPercentItem';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';
import { useGetReviewPillsQuery } from '@app/src/api/taxDataApi';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { formUploadAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { SLUG__STATE_INCOME } from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  getExpenseSplitQuestions,
  getIncomeSplitQuestions
} from '@app/src/taxflow/sections/state/utils/multistateUtils';
import {
  queryResultsSelector,
  currentAnswerSelector,
  currentQuestionSelector,
  resultLoadingSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowFormPercentageSplit = ({
  currentQuestion,
  currentAnswer,
  resultLoading,
  queryResults,
  jobCategories,
  formUploadAttempts,
  setCurrentAnswer
}) => {
  const [formQuestions, setFormQuestions] = useState([]);

  const { data: reviewPills } = useGetReviewPillsQuery();

  // Sync job name in split questions with the text that would appear on a review pill
  const getJobName = useCallback(
    (job) => {
      const name = _.chain(reviewPills)
        .map('pills')
        .flatten()
        .find({
          collectionType: job.coll_type,
          collectionId: job.coll_id
        })
        .get('text')
        .value();

      if (_.isNil(name) || _.isEmpty(name)) {
        defaultCaptureMessage('review: could not get job name from review pills', { job });
      }

      return name;
    },
    [reviewPills]
  );

  useEffect(() => {
    let generatedFormQuestions;
    if (currentQuestion.slug === SLUG__STATE_INCOME) {
      generatedFormQuestions = getIncomeSplitQuestions({ queryResults, getJobName });
    } else {
      generatedFormQuestions = getExpenseSplitQuestions({ queryResults, getJobName });
    }
    setFormQuestions(generatedFormQuestions);
  }, [jobCategories, queryResults, currentQuestion, reviewPills, formUploadAttempts, getJobName]);

  const onChange = (result) => {
    const answerValue = currentAnswer.value ? currentAnswer.value : {};
    const newAnswer = {
      ...answerValue,
      [result.slug]: result
    };
    setCurrentAnswer({ value: newAnswer });
  };

  const renderQuestionInput = (question, onChange) => {
    const answer = currentAnswer && currentAnswer.value ? currentAnswer.value[question.slug] : null;

    if (question) {
      return (
        <TaxFlowFormPercentItem
          key={question.slug}
          question={question}
          answer={answer}
          decimalScale={0}
          inputMode='numeric'
          onChange={(arg) => onChange(arg)}
        />
      );
    }
  };

  if (resultLoading) return null;

  return (
    <form className='steps-signup-form'>
      <div className={'steps-body'}>
        {formQuestions &&
          formQuestions.map((job, index) => {
            const slugs = job.questions.map((question) => question.slug);
            const title = _.get(job, 'incomeAmount')
              ? `${job.jobName} ($${job.incomeAmount.toLocaleString()})`
              : job.jobName;
            return (
              <div key={index} className='form-group'>
                <h1>
                  {
                    <TaxValidationLabel
                      question={{ collectionType: currentQuestion.collectionType, slug: slugs }}
                      title={title}
                    />
                  }
                </h1>
                {job.jobType && <div style={{ marginBottom: '8px', fontStyle: 'italic' }}>{job.jobType}</div>}
                {job.questions.map((question) => {
                  return renderQuestionInput(question, onChange);
                })}
                <TaxValidationWarning
                  question={currentQuestion}
                  answer={currentAnswer}
                  slug1={slugs[0]}
                  slug2={slugs[1]}
                />
              </div>
            );
          })}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  resultLoading: resultLoadingSelector(state),
  queryResults: queryResultsSelector(state),
  jobCategories: jobCategoriesSelector(state),
  formUploadAttempts: formUploadAttemptsSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowFormPercentageSplit = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormPercentageSplit);

export default ConnectedTaxFlowFormPercentageSplit;
