import {
  RESET_MERCHANT_NAME_SEARCH,
  SET_ACCOUNT_FILTER_SELECTIONS,
  SET_ALL_CATEGORIES,
  SET_AMOUNT_FILTER_SELECTIONS,
  SET_AUTO_RULE,
  SET_CATEGORY_AMOUNTS,
  SET_CATEGORY_FILTER_SELECTIONS,
  SET_EXPENSES,
  SET_EXPENSES_ERROR,
  SET_EXPENSES_YEAR,
  SET_EXPENSE_REVIEW_ACCOUNTS,
  SET_EXPENSE_REVIEW_CURRENT_RULE,
  SET_EXPENSE_REVIEW_MODAL_TYPE,
  SET_EXPENSE_REVIEW_RULES,
  SET_HAS_FETCHED_MAX_EXPENSES,
  SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING,
  SET_IS_EXPORTING,
  SET_IS_INITIAL_EXPENSES_LOAD,
  SET_IS_LOADING_EXPENSES,
  SET_MANUAL_EXPENSE_AMOUNT,
  SET_MANUAL_EXPENSE_DATA,
  SET_MERCHANT_NAME_FILTER_QUERY,
  SET_MERCHANT_NAME_SEARCH,
  SET_MERCHANT_SEARCH_LOADING,
  SET_MERCHANT_SEARCH_RESULTS,
  SET_RETRO_INTERVAL,
  SET_RETRO_STATUS,
  SET_SAVINGS_AMOUNT,
  SET_SELECTED_EXPENSE,
  SET_SIDEBAR_COMPONENT,
  SET_STATUS_FILTER_SELECTIONS,
  SET_BULK_EDIT_EXPENSES,
  SET_BULK_EDIT_LOADING
} from '@app/src/actions/types';
import { getDefaultExpenseYear } from '@app/src/utils/expenseUtils';

const initialState = {
  expenses: { orderedTransactionIds: [], expensesById: {} },
  loading: true,
  expensesError: '',
  expensesYear: getDefaultExpenseYear(),
  isInitialExpensesLoad: true,
  hasFetchedMaxExpenses: false,
  savingsAmount: 0,
  categoryAmounts: {},
  accounts: [],
  sidebarComponent: null,
  categoryFilterSelections: [],
  accountFilterSelections: [],
  statusFilterSelections: [],
  amountFilterSelections: [],
  merchantNameSearch: '',
  merchantSearchResults: [],
  merchantSearchLoading: false,
  merchantNameFilterQuery: '',
  selectedExpense: {},
  rules: [],
  currentRule: null,
  autoRule: {},
  allCategories: [],
  isModalSubmitting: false,
  expenseReviewModalType: '',
  manualExpenseData: {},
  manualExpenseAmount: null,
  expenseReviewModalShow: false,
  retroStatus: '',
  retroInterval: 0,
  isExporting: false,
  bulkEditExpenses: [],
  bulkEditLoading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EXPENSES:
      return {
        ...state,
        expenses: action.payload
      };

    case SET_IS_LOADING_EXPENSES:
      return {
        ...state,
        loading: action.payload
      };

    case SET_IS_INITIAL_EXPENSES_LOAD:
      return {
        ...state,
        isInitialExpensesLoad: action.payload
      };

    case SET_EXPENSES_ERROR:
      return {
        ...state,
        expensesError: action.payload
      };

    case SET_EXPENSES_YEAR:
      return {
        ...state,
        expensesYear: action.payload
      };

    case SET_HAS_FETCHED_MAX_EXPENSES:
      return {
        ...state,
        hasFetchedMaxExpenses: action.payload
      };

    case SET_SAVINGS_AMOUNT:
      return {
        ...state,
        savingsAmount: action.payload
      };

    case SET_CATEGORY_AMOUNTS:
      return {
        ...state,
        categoryAmounts: action.payload
      };

    case SET_EXPENSE_REVIEW_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload
      };

    case SET_CATEGORY_FILTER_SELECTIONS:
      return {
        ...state,
        categoryFilterSelections: action.payload
      };

    case SET_EXPENSE_REVIEW_RULES:
      return {
        ...state,
        rules: action.payload
      };

    case SET_EXPENSE_REVIEW_CURRENT_RULE:
      return {
        ...state,
        currentRule: action.payload
      };

    case SET_AUTO_RULE:
      return {
        ...state,
        autoRule: action.payload
      };

    case SET_ACCOUNT_FILTER_SELECTIONS:
      return {
        ...state,
        accountFilterSelections: action.payload
      };

    case SET_STATUS_FILTER_SELECTIONS:
      return {
        ...state,
        statusFilterSelections: action.payload
      };

    case SET_AMOUNT_FILTER_SELECTIONS:
      return {
        ...state,
        amountFilterSelections: action.payload
      };

    case SET_MERCHANT_NAME_SEARCH:
      return {
        ...state,
        merchantNameSearch: action.payload
      };

    case SET_MERCHANT_NAME_FILTER_QUERY:
      return {
        ...state,
        merchantNameFilterQuery: action.payload
      };

    case SET_SELECTED_EXPENSE:
      return {
        ...state,
        selectedExpense: action.payload
      };

    case SET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload
      };

    case SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING:
      return {
        ...state,
        isModalSubmitting: action.payload
      };

    case SET_SIDEBAR_COMPONENT:
      return {
        ...state,
        sidebarComponent: action.payload
      };

    case SET_EXPENSE_REVIEW_MODAL_TYPE:
      return {
        ...state,
        expenseReviewModalType: action.payload
      };

    case SET_MANUAL_EXPENSE_DATA: {
      return {
        ...state,
        manualExpenseData: action.payload
      };
    }

    case SET_MANUAL_EXPENSE_AMOUNT: {
      return {
        ...state,
        manualExpenseAmount: action.payload
      };
    }

    case SET_RETRO_STATUS: {
      return {
        ...state,
        retroStatus: action.payload
      };
    }

    case SET_RETRO_INTERVAL: {
      return {
        ...state,
        retroInterval: action.payload
      };
    }
    case SET_MERCHANT_SEARCH_RESULTS:
      return {
        ...state,
        merchantSearchResults: action.payload
      };

    case SET_MERCHANT_SEARCH_LOADING:
      return {
        ...state,
        merchantSearchLoading: action.payload
      };

    case RESET_MERCHANT_NAME_SEARCH:
      return {
        ...state,
        merchantNameSearch: '',
        merchantNameFilterQuery: '',
        merchantSearchResults: []
      };

    case SET_IS_EXPORTING:
      return {
        ...state,
        isExporting: action.payload
      };

    case SET_BULK_EDIT_EXPENSES:
      return {
        ...state,
        bulkEditExpenses: action.payload
      };

    case SET_BULK_EDIT_LOADING:
      return {
        ...state,
        bulkEditLoading: action.payload
      };

    default:
      return state;
  }
}
