import React, { Component } from 'react';
import _ from 'lodash';
import TaxFlowNumberItem from '@app/src/Components/TaxFlow/Question/TaxFlowNumberItem';

class TaxFlowMoneyItem extends Component {
  render() {
    const { currentQuestion, resultLoading, ...props } = this.props;

    let newQuestion = currentQuestion;
    if (!resultLoading) {
      newQuestion = {
        ...currentQuestion,
        question_meta: {
          ..._.get(currentQuestion, ['question_meta'], {}),
          prefix: '$'
        }
      };
    }

    return (
      <TaxFlowNumberItem
        currentQuestion={newQuestion}
        resultLoading={resultLoading}
        id={currentQuestion.slug}
        decimalScale={0}
        {...props}
      />
    );
  }
}

export default TaxFlowMoneyItem;
