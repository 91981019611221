import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import taxDataApi, { TAGS } from '@app/src/api/taxDataApi';

/**
 * Hook routing to, given a change in ui stage, the new ui stage's landing page
 * Polls for ui stage changes every 15 secs
 */
export const useUpdatePageOnUIStageChange = () => {
  const dispatch = useDispatch();
  const { data: campaign } = useGetCampaignQuery(undefined, { pollingInterval: 15000 });
  const uiStage = campaign?.ui_stage;
  const prevUIStage = usePrevious(uiStage);

  useEffect(() => {
    if (prevUIStage === uiStage) {
      return;
    }
    dispatch(
      taxDataApi.util.invalidateTags([TAGS.SUBMIT_ISSUES, TAGS.TAX_AMOUNTS, TAGS.SUBMIT_TIMESTAMP, TAGS.RETURN_STATUS])
    );
  }, [uiStage, prevUIStage, dispatch]);
};
